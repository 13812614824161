import Offer from "../../../../domain/modules/offer/entity/Offer";
import OfferList from "../../../../domain/modules/offer/valueObject/OfferList";
import MultilingualString from "../../../../domain/modules/MultilingualString";
import IMapper from "../../../../infrastructure/IMapper";
import { OfferViewModel } from "../viewModel/OfferViewModel";

export default class OfferPresentation implements IMapper<Offer, OfferViewModel> {
  presentOffers(list: OfferList) {
    const result: OfferViewModel[] = [];
    list.offers.map((o) => result.push(this.map(o)));

    return result;
  }

  map(offer: Offer): OfferViewModel {
    try {
      const { id, name, header, status, img1, img2, category, description, details } = offer;

      return {
        id: id.getId(),
        name: this.extractMultilingualValue(name),
        header: this.extractMultilingualValue(header),
        status: status.value.toLocaleLowerCase(),
        category: category.value === "ENERGY_PRODUCT" ? "ENERGY_PRODUCT" : "HARDWARE_PRODUCT",
        img1: this.extractMultilingualValue(img1),
        img2: this.extractMultilingualValue(img2),
        description: this.extractMultilingualValue(description),
        details: this.extractMultilingualValue(details)
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }

  private extractMultilingualValue(data: MultilingualString) {
    return {
      en: data.en,
      de: data.de,
      fr: data.fr,
      it: data.it
    };
  }
}
