import NetworkController from "../../network/NetworkController";
import IUserDataSource, { UserViewModel } from "./IUserDataSource";

const mockData = [
  {
    id: "id120",
    email: "jane@gmail.com",
    firstName: "Jane",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id121",
    email: "john@gmail.com",
    firstName: "John",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id122",
    email: "mark@gmail.com",
    firstName: "Mark",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id123",
    email: "marrie@gmail.com",
    firstName: "Marie",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id124",
    email: "jane@gmail.com",
    firstName: "Jane",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id125",
    email: "john@gmail.com",
    firstName: "John",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id126",
    email: "mark@gmail.com",
    firstName: "Mark",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id127",
    email: "marrie@gmail.com",
    firstName: "Marie",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id128",
    email: "jane@gmail.com",
    firstName: "Jane",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  },
  {
    id: "id129",
    email: "john@gmail.com",
    firstName: "John",
    lastName: "Smith",
    language: "en",
    zipCode: "4563",
    city: "Los Angeles",
    createdAt: new Date().toString()
  }
];

export default class UserDataSource implements IUserDataSource {
  constructor(private nwc: NetworkController) {}

  public async getUsers(): Promise<UserViewModel[]> {
    try {
      return mockData;
      // const users: any = await this.nwc.request({
      //   url: "/notify-app/users",
      //   method: "GET",
      //   useToken: true
      // });
      // return users.data;
    } catch (error: any) {
      throw new Error(`[getUsers] - ${error.message}`);
    }
  }
}
