import Entity from "../../../base/entity/Entity";
import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import StringId from "../../../base/valueObject/uniqueEntityID/StringId";

interface MyProfileProps {
  name: NotEmptyString;
  utility: NotEmptyString;
}

export default class MyProfile extends Entity<MyProfileProps, string> {
  get id(): StringId {
    return StringId.create(this._id.getId());
  }

  get name(): NotEmptyString {
    return this.props.name;
  }

  get utility(): NotEmptyString {
    return this.props.utility;
  }
}
