import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MyProfileViewModel } from "../../presenter/myProfile/viewModel/MyProfileViewModel";

interface MyProfileState {
  id: string;
  name: string;
  utility: string;
  error?: string;
}

const initialState: MyProfileState = {
  id: "",
  name: "",
  utility: ""
};

export const myProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    myProfileLoaded: (state, { payload }: PayloadAction<{ profile: MyProfileViewModel }>) => {
      const newState = Object.entries(payload.profile).reduce((acc, curr) => {
        let key = curr[0];
        let val = curr[1];
        return { ...acc, [key]: val };
      }, {});

      return {
        ...initialState,
        ...newState
      };
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
    },
    viewUnloaded: () => initialState
  }
});

export const { myProfileLoaded, errorHappened, viewUnloaded } = myProfileSlice.actions;
