import getDefaultMultilingualStringViewModel, { MultilingualStringViewModel } from "./MultilingualStringViewModel";

export type OfferInputsModel = {
  field: string;
  value: string;
};

export type LanguageViewModel = "en" | "de" | "fr" | "it";

export type CategoryViewModel = "HARDWARE_PRODUCT" | "ENERGY_PRODUCT";

export type OfferViewModel = {
  id: number;
  status: string;
  category: string;
  name: MultilingualStringViewModel;
  header: MultilingualStringViewModel;
  img1: MultilingualStringViewModel;
  img2: MultilingualStringViewModel;
  description: MultilingualStringViewModel;
  details: MultilingualStringViewModel;
};

export const getDefaultOfferViewModel = (): OfferViewModel => {
  return {
    id: -1,
    category: "HARDWARE_PRODUCT",
    name: getDefaultMultilingualStringViewModel(),
    header: getDefaultMultilingualStringViewModel(),
    status: "draft",
    img1: getDefaultMultilingualStringViewModel(),
    img2: getDefaultMultilingualStringViewModel(),
    description: getDefaultMultilingualStringViewModel(),
    details: getDefaultMultilingualStringViewModel(),
  };
};

export default getDefaultOfferViewModel;
