import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventViewModel } from "../../../infrastructure/repository/eventRepository/IEventRepository";

interface EventListState {
  eventList: EventViewModel[];
  filtering: {
    from: string;
    to: string;
  };
  loading: boolean;
  error?: string;
}

const initialState: EventListState = {
  eventList: [],
  filtering: {
    from: new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toString(),
    to: new Date().toString()
  },
  loading: false
};

export const eventListSlice = createSlice({
  name: "eventList",
  initialState,
  reducers: {
    eventsLoaded: (state, { payload }: PayloadAction<{ events: EventViewModel[] }>) => {
      state.loading = false;
      state.eventList = payload.events;
    },
    historyFilteringSet: (state, { payload }: PayloadAction<{ name: "from" | "to"; value: string }>) => {
      state.filtering[payload.name] = payload.value;
    },
    historyFilteringReset: (state) => {
      state.filtering.from = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toString();
      state.filtering.to = new Date().toString();
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState
  }
});

export const {
  requestFinished,
  requestStarted,
  errorHappened,
  viewUnloaded,
  historyFilteringReset,
  historyFilteringSet,
  eventsLoaded
} = eventListSlice.actions;
