import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import Entity from "../../../base/entity/Entity";
import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import Language from "../../Language";
import ZipCode from "../../ZipCode";
import Name from "../../Name";
import Email from "../../Email";

export class CustomerError extends Error {
  constructor(message: string) {
    super(`[Customer] Error - ${message}`);
  }
}

interface CustomerProps {
  name: Name;
  email: Email;
  language: Language;
  zipCode: ZipCode;
  distributor: NotEmptyString;
  createdAt: NotEmptyString;
  modifiedAt?: NotEmptyString;
  obisCode: string;
  mainMeter: string;
}

export default class Customer extends Entity<CustomerProps, string> {
  get id(): StringId {
    return StringId.create(this._id.getId());
  }

  get name(): Name {
    return this.props.name;
  }

  get email(): Email {
    return this.props.email;
  }

  get language(): Language {
    return this.props.language;
  }

  get zipCode(): ZipCode {
    return this.props.zipCode;
  }

  get distributor(): NotEmptyString {
    return this.props.distributor;
  }

  get createdAt(): NotEmptyString {
    return this.props.createdAt;
  }

  get modifiedAt(): NotEmptyString | undefined {
    return this.props.createdAt;
  }

  get obisCode(): string {
    return this.props.obisCode;
  }

  get mainMeter(): string {
    return this.props.mainMeter;
  }
}
