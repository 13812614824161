import StringId from "../../../base/valueObject/uniqueEntityID/StringId";
import Entity from "../../../base/entity/Entity";
import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import OrderStatus from "../valueObject/OrderStatus";
import OfferInfo from "../valueObject/OfferInfo";
import OrderHistoryList from "../valueObject/OrderHistoryList";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import NoteList from "../valueObject/NoteList";

export class OrderError extends Error {
  constructor(message: string) {
    super(`[Order] Error - ${message}`);
  }
}

interface OrderProps {
  orderNumber: NotEmptyString;
  customerId?: StringId;
  offerInfo: OfferInfo;
  status: OrderStatus;
  notes: NoteList;
  orderHistory: OrderHistoryList;
  declineReason?: NotEmptyString;
  deactivationReason?: NotEmptyString;
  agreementId?: NumericId;
}

export default class Order extends Entity<OrderProps, number> {
  get id(): NumericId {
    return NumericId.create(this._id.getId());
  }

  get orderNumber(): NotEmptyString {
    return this.props.orderNumber;
  }

  get customerId(): StringId | undefined {
    return this.props.customerId;
  }

  get offerInfo(): OfferInfo {
    return this.props.offerInfo;
  }

  get status(): OrderStatus {
    return this.props.status;
  }

  get notes(): NoteList {
    return this.props.notes;
  }

  get orderHistory(): OrderHistoryList {
    return this.props.orderHistory;
  }

  get declineReason(): NotEmptyString | undefined {
    return this.props.declineReason;
  }

  get deactivationReason(): NotEmptyString | undefined {
    return this.props.deactivationReason;
  }

  get agreementId(): NumericId | undefined {
    return this.props.agreementId;
  }
}
