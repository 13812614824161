import IUserDataSource, { UserViewModel } from "../../dataSource/userDataSource/IUserDataSource";
import IUserRepository from "./IUserRepository";

export default class UserRepository implements IUserRepository {
  constructor(private _dataSource: IUserDataSource) {}

  public async getUsers(): Promise<UserViewModel[]> {
    return await this._dataSource.getUsers();
  }
}
