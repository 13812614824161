import { ValueObject } from "../base/valueObject/ValueObject";


interface LanguageProps {
    value: string;
}

export default class Language extends ValueObject<LanguageProps> {

    private static DEFAULT_LANGUAGE = "en";

    get value(): string {
        return this.props.value;
    }

    private constructor(props: LanguageProps) {
        super(props);
    }

    public static create(value: string): Language {
        return new Language({ value: Language.validate(value) ? value : Language.DEFAULT_LANGUAGE })
    }

    private static validate(value?: string): boolean {
        if (value === undefined || value === null)
            return false;
        return ["en", "de", "fr", "it"].indexOf(value.toLocaleLowerCase()) !== -1;
    }

    public equals(vo: ValueObject<LanguageProps>): boolean {
        return vo.props.value === this.props.value
    }
}