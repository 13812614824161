import Customer from "../../../../domain/modules/customer/entity/Customer";
import { CustomerDTO } from "../../../dataSource/customerDataSource/ICustomerDataSource";
import IMapper from "../../../IMapper";
import CustomerMapper from "../mapper/CustomerMapper";
import ICustomerMapperFactory from "./ICustomerMapperFactory";

export default class CustomerMapperFactory implements ICustomerMapperFactory {
    getCustomerMapper(): IMapper<CustomerDTO, Customer> {
        return new CustomerMapper();
    }
}