import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getDefaultMultilingualStringViewModel, { MultilingualStringViewModel } from "../../presenter/offer/viewModel/MultilingualStringViewModel";
import { NoteViewModel, OrderHistoryViewModel, OrderViewModel } from "../../presenter/order/viewModel/OrderViewModel";

interface OrderState {
  id: number;
  activationDate: string;
  orderNumber: string;
  customerId: string;
  offerInfo: {
    id: number;
    title: MultilingualStringViewModel;
  };
  details: {
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    zipCode: string;
    city: string;
    telephone?: string;
    meterManufacturer: string;
    meterModel: string;
    meterHasWiFiAccess: boolean;
    meterHasPowerOutletAccess: boolean;
    hasWiFiAtHome: boolean;
  };
  status: string;
  orderHistory: OrderHistoryViewModel[];
  notes: NoteViewModel[];
  newNote: string;
  declineReason?: string;
  deactivationReason?: string;
  agreementId?: number;
  selectedConfigurations: {
    name: string;
    value: string;
  }[];
  loading: boolean;
  updateNoteLoading: boolean;
  error?: string;
}

const initialState: OrderState = {
  id: -1,
  activationDate: new Date().toString(),
  orderNumber: "",
  customerId: "",
  offerInfo: {
    id: -1,
    title: getDefaultMultilingualStringViewModel(),
  },
  details: {
    firstName: "",
    lastName: "",
    email: "",
    street: "",
    zipCode: "",
    city: "",
    meterManufacturer: "",
    meterModel: "",
    meterHasWiFiAccess: false,
    meterHasPowerOutletAccess: false,
    hasWiFiAtHome: false,
  },
  status: "",
  newNote: "",
  orderHistory: [],
  notes: [],
  selectedConfigurations: [],
  loading: false,
  updateNoteLoading: false,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    inputChanged: (state, { payload }: PayloadAction<{ field: "status" | "declineReason" | "deactivationReason"; value: string }>) => {
      state[payload.field] = payload.value;
    },
    configurationsCleared: (state) => {
      state.selectedConfigurations = [];
    },
    configurationsChanged: (state, { payload }: PayloadAction<{ configurations: { name: string; value: string }[] }>) => {
      state.selectedConfigurations = payload.configurations;
    },
    noteSet: (state, { payload }: PayloadAction<{ note: string }>) => {
      state.newNote = payload.note;
    },
    noteUpdateLoaded: (state) => {
      state.updateNoteLoading = true;
    },
    noteUpdated: (state, { payload }: PayloadAction<{ updatedNote: NoteViewModel }>) => {
      state.updateNoteLoading = false;
      state.newNote = "";
      state.notes = [...state.notes, payload.updatedNote];
    },
    orderLoaded: (state, { payload }: PayloadAction<{ order: OrderViewModel }>) => {
      const { order } = payload;
      const newState = Object.entries(order).reduce((acc, curr) => {
        let key = curr[0];
        let val = curr[1];
        return { ...acc, [key]: val };
      }, {});

      return { ...state, loading: false, ...newState };
    },
    activationDateSet: (state, { payload }: PayloadAction<string>) => {
      state.activationDate = payload;
    },
    statusChanged: (state, { payload }: PayloadAction<{ status: string; note: NoteViewModel }>) => {
      const updatedHistory = [
        ...state.orderHistory,
        {
          status: payload.status,
          timestamp: new Date().toString(),
        },
      ];
      state.loading = false;
      state.status = payload.status;
      state.orderHistory = updatedHistory;
      state.notes = [...state.notes, payload.note];
    },
    espOrderCompleted: (state, { payload }: PayloadAction<{ note: NoteViewModel }>) => {
      const completedEspHistory = [
        ...state.orderHistory,
        {
          status: "WAITING_ON_ACTIVATION",
          timestamp: new Date().toString(),
          activation: state.activationDate.toString(),
        },
      ];
      state.loading = false;
      state.status = "WAITING_ON_ACTIVATION";
      state.orderHistory = completedEspHistory;
      state.notes = [...state.notes, payload.note];
    },
    hwOrderCompleted: (state, { payload }: PayloadAction<{ note: NoteViewModel }>) => {
      const completedHwHistory = [
        ...state.orderHistory,
        {
          status: "COMPLETED",
          timestamp: new Date().toString(),
        },
      ];
      state.loading = false;
      state.status = "COMPLETED";
      state.orderHistory = completedHwHistory;
      state.notes = [...state.notes, payload.note];
    },
    orderDeactivated: (state, { payload }: PayloadAction<{ deactivationReason: string; note: NoteViewModel }>) => {
      const deactivatedHistory = [
        ...state.orderHistory,
        {
          status: "DEACTIVATED",
          timestamp: new Date().toString(),
        },
      ];
      state.loading = false;
      state.status = "DEACTIVATED";
      state.orderHistory = deactivatedHistory;
      state.deactivationReason = payload.deactivationReason;
      state.notes = [...state.notes, payload.note];
    },
    orderDeclined: (state, { payload }: PayloadAction<{ declineReason: string; note: NoteViewModel }>) => {
      const declinedHistory = [
        ...state.orderHistory,
        {
          status: "DECLINED",
          timestamp: new Date().toString(),
        },
      ];
      state.loading = false;
      state.status = "DECLINED";
      state.declineReason = payload.declineReason;
      state.orderHistory = declinedHistory;
      state.notes = [...state.notes, payload.note];
    },
    viewUnloaded: () => initialState,
  },
});

export const {
  inputChanged,
  errorHappened,
  requestStarted,
  requestFinished,
  viewUnloaded,
  orderLoaded,
  orderDeactivated,
  espOrderCompleted,
  statusChanged,
  noteSet,
  noteUpdateLoaded,
  noteUpdated,
  configurationsChanged,
  activationDateSet,
  configurationsCleared,
  orderDeclined,
  hwOrderCompleted,
} = orderSlice.actions;
