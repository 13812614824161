import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import MyProfile from "../../../../domain/modules/myProfile/entity/MyProfile";
import { MyProfileDTO } from "../../../dataSource/myProfileDataSource/IMyProfileDataSource";
import IMapper from "../../../IMapper";

export default class MyProfileMapper implements IMapper<MyProfileDTO, MyProfile> {
  map(input: MyProfileDTO): MyProfile {
    const { name, utility } = input;
    return new MyProfile(
      {
        name: NotEmptyString.create(name),
        utility: NotEmptyString.create(utility)
      },
      StringId.create(input.sub)
    );
  }
}
