import MyProfile from "../../../../domain/modules/myProfile/entity/MyProfile";
import { MyProfileDTO } from "../../../dataSource/myProfileDataSource/IMyProfileDataSource";
import IMapper from "../../../IMapper";
import MyProfileMapper from "../mapper/MyProfileMapper";
import IMyProfileMapperFactory from "./IMyProfileMapperFactory";

export default class MyProfileMapperFactory implements IMyProfileMapperFactory {
    getMyProfileMapper(): IMapper<MyProfileDTO, MyProfile> {
        return new MyProfileMapper();
    }
}