import { ValueObject } from "../../../base/valueObject/ValueObject";
import MeterFeatures from "./MeterFeatures";

export class MeterInfoError extends Error {
    constructor(message: string) {
        super(`[MeterInfo] Error - ${message}`)
    }
}

interface MeterInfoProps {
    manufacturer: string;
    model: string;
    features: MeterFeatures;
}

export default class MeterInfo extends ValueObject<MeterInfoProps> {

    get manufacturer(): string {
        return this.props.manufacturer;
    }

    get model(): string {
        return this.props.model;
    }

    get features(): MeterFeatures {
        return this.props.features;
    }

    private constructor(props: MeterInfoProps) {
        super(props);
    }

    public static create(manufacturer: string, model: string, features: MeterFeatures): MeterInfo {
        return new MeterInfo({ manufacturer, model, features });
    }

    public static validate(value: string): boolean {
        if (!value) return false;
        return true;
    }

    public equals(vo: ValueObject<MeterInfoProps>): boolean {
        if (vo.props.model === this.props.model
            && vo.props.manufacturer === this.props.manufacturer
            && vo.props.features.equals(this.props.features))
            return true;
        else return false;
    }
}