import ISettingsDataSource, {
  NotificationSubscriberDTO
} from "../../dataSource/settingsDataSource/ISettingsDataSource";
import ISettingsRepository, { SettingsViewModel, ZipCodeViewModel } from "./ISettingsRepository";

export default class SettingsRepository implements ISettingsRepository {
  constructor(private _dataSource: ISettingsDataSource) {}

  async getSettings(): Promise<SettingsViewModel> {
    try {
      const settings = await this._dataSource.getSettings();
      const mappedZipCodes = settings.zipCodes.map((zip) => this.mapZipCode(zip));
      const mappedDistricts = settings.districts
        .map((district) => this.mapDistrict(district))
        .filter((district) => district.name !== "ALL");

      return { ...settings, zipCodes: mappedZipCodes, districts: mappedDistricts };
    } catch (err: any) {
      throw new Error(`SettingsRepository - [getSettings] - ${err.message}`);
    }
  }

  async deleteZipCode(zipCodes: ZipCodeViewModel[]): Promise<void> {
    try {
      const zipDTOs = this.mapZipCodeDTO(zipCodes);
      await this._dataSource.deleteZipCode(zipDTOs);
    } catch (err: any) {
      throw new Error(`SettingsRepository - [deleteZipCode] - ${err.message}`);
    }
  }

  async deleteSubscriber(email: string): Promise<void> {
    try {
      await this._dataSource.deleteSubscriber(email);
    } catch (err: any) {
      throw new Error(`SettingsRepository - [deleteSubscriber] - ${err.message}`);
    }
  }

  async addZipCode(newZipCode: string, zipCodes: ZipCodeViewModel[]): Promise<ZipCodeViewModel[]> {
    try {
      const zipCodeToAdd = { zip: newZipCode };

      let updatedZipCodes = [...zipCodes, zipCodeToAdd];
      updatedZipCodes = updatedZipCodes.filter(
        (value, index, self) => index === self.findIndex((s) => s.zip === value.zip)
      );

      const zipDTOs = this.mapZipCodeDTO(updatedZipCodes);

      await this._dataSource.addZipCode(zipDTOs);
      return updatedZipCodes;
    } catch (err: any) {
      throw new Error(`SettingsRepository - [addZipCode] - ${err.message}`);
    }
  }

  async addSubscriber(newSubscriber: string): Promise<NotificationSubscriberDTO> {
    try {
      const subscriberToAdd = { email: newSubscriber, description: "" };

      await this._dataSource.addSubscriber([subscriberToAdd]);
      return subscriberToAdd;
    } catch (err: any) {
      throw new Error(`SettingsRepository - [addSubscriber] - ${err.message}`);
    }
  }

  async getDistricts(): Promise<string[]> {
    try {
      const allDistricts = await this._dataSource.getDistricts();
      return allDistricts.filter((district) => district !== "ALL");
    } catch (err: any) {
      throw new Error(`SettingsRepository - [getDistricts] - ${err.message}`);
    }
  }

  async addDistrict(district: string): Promise<void> {
    try {
      await this._dataSource.addDistrict(district);
    } catch (err: any) {
      throw new Error(`SettingsRepository - [addDistrict] - ${err.message}`);
    }
  }

  async deleteDistrict(district: string): Promise<string> {
    try {
      await this._dataSource.deleteDistrict(district);
      return district;
    } catch (err: any) {
      throw new Error(`SettingsRepository - [deleteDistrict] - ${err.message}`);
    }
  }

  async editDistrict(oldName: string, newName: string): Promise<void> {
    try {
      await this._dataSource.editDistrict(oldName, newName);
    } catch (err: any) {
      throw new Error(`SettingsRepository - [editDistrict] - ${err.message}`);
    }
  }

  private mapZipCode(zipCode: string) {
    return { zip: zipCode };
  }

  private mapDistrict(district: string) {
    return { name: district };
  }

  private mapZipCodeDTO(zipCodes: ZipCodeViewModel[]): string[] {
    const result: string[] = [];
    zipCodes.map((z) => result.push(z.zip));
    return result;
  }
}
