import Customer from "../../../../domain/modules/customer/entity/Customer";
import CustomerList from "../../../../domain/modules/customer/valueObject/CustomerList";
import Order from "../../../../domain/modules/order/entity/Order";
import OrderListItem from "../../../../domain/modules/order/entity/OrderListItem";
import Note from "../../../../domain/modules/order/valueObject/Note";
import OrderHistory from "../../../../domain/modules/order/valueObject/OrderHistory";
import OrderHistoryList from "../../../../domain/modules/order/valueObject/OrderHistoryList";
import OrderList from "../../../../domain/modules/order/valueObject/OrderList";
import IMapper from "../../../../infrastructure/IMapper";
import { getStatus } from "../../../components/table/resources";
import {
  NoteViewModel,
  OrderHistoryViewModel,
  OrderListItemViewModel,
  OrderViewModel
} from "../viewModel/OrderViewModel";

export default class OrderPresentation implements IMapper<Order, OrderViewModel> {
  public presentOrders(orderList: OrderList): OrderListItemViewModel[] {
    const result: OrderListItemViewModel[] = [];
    orderList.orders.map((o) => result.push(this.mapListItem(o)));
    return result.sort((a, b) => a.id - b.id);
  }

  public presentOrderList(orderList: OrderList, customerList: CustomerList): OrderListItemViewModel[] {
    let result: OrderListItemViewModel[] = [];

    orderList.orders.forEach((order) => {
      if (!order.customerId) {
        result.push(this.mapListItem(order));
      } else if (customerList.customers.find((customer) => order.customerId?.isEqual(customer.id))) {
        result.push(
          this.mapListItem(
            order,
            customerList.customers.find((customer) => order.customerId?.isEqual(customer.id))
          )
        );
      } else {
        result.push(this.mapListItem(order));
      }
    });
    return result;
  }

  public mapListItem(input: OrderListItem, customer?: Customer): OrderListItemViewModel {
    const {
      id,
      customerId,
      offerInfo,
      status,
      notes,
      orderNumber,
      declineReason,
      deactivationReason,
      agreementId,
      orderHistory
    } = input;

    return {
      id: id.getId(),
      orderNumber: orderNumber.value,
      customerId: customerId ? customerId.getId() : undefined,
      customerName: customer ? `${customer.name.firstName.value} ${customer.name.lastName.value}` : "",
      notes: notes.notes.map((n) => this.mapNote(n)),
      status: status.value,
      declineReason: declineReason ? declineReason.value : undefined,
      deactivationReason: deactivationReason ? deactivationReason.value : undefined,
      agreementId: agreementId ? agreementId.getId() : undefined,
      offerInfo: {
        id: offerInfo.id.getId(),
        title: {
          en: offerInfo.title.en,
          de: offerInfo.title.de,
          fr: offerInfo.title.fr,
          it: offerInfo.title.it
        }
      },
      orderHistory: this.convertHistoryListToViewModel(orderHistory)
    };
  }

  public map(input: Order): OrderViewModel {
    const {
      id,
      customerId,
      details,
      offerInfo,
      status,
      notes,
      orderNumber,
      declineReason,
      deactivationReason,
      agreementId,
      orderHistory
    } = input;

    return {
      id: id.getId(),
      orderNumber: orderNumber.value,
      customerId: customerId ? customerId.getId() : undefined,
      details: {
        firstName: details.name.firstName.value,
        lastName: details.name.lastName.value,
        email: details.email.value,
        street: details.address.street.value,
        zipCode: details.address.zipCode.value,
        city: details.address.city.value,
        meterManufacturer: details.meterInfo.manufacturer,
        meterModel: details.meterInfo.model,
        meterHasWiFiAccess: details.meterInfo.features.wifiAccess,
        meterHasPowerOutletAccess: details.meterInfo.features.powerOutletAccess,
        hasWiFiAtHome: details.meterInfo.features.wifiAtHome,
        telephone: details.telephone
      },
      notes: notes.notes.filter((n) => n.body !== "##ACTIVATED##").map((n) => this.mapNote(n)),
      status: status.value,
      declineReason: declineReason ? declineReason.value : undefined,
      deactivationReason: deactivationReason ? deactivationReason.value : undefined,
      agreementId: agreementId ? agreementId.getId() : undefined,
      offerInfo: {
        id: offerInfo.id.getId(),
        title: {
          en: offerInfo.title.en,
          de: offerInfo.title.de,
          fr: offerInfo.title.fr,
          it: offerInfo.title.it
        }
      },
      orderHistory: this.convertHistoryListToViewModel(orderHistory)
    };
  }

  private mapNote(note: Note): NoteViewModel {
    return {
      autoGenerated: note.autoGenerated,
      authorizedBy: note.authorizedBy.value,
      body: note.body.includes("#") ? this.setNoteStatus(note.body.replace(/#/g, "").trim()) : note.body,
      timestamp: note.timestamp.toString()
    };
  }

  private setNoteStatus(body: string) {
    return body.includes("WAITING_ON_ACTIVATION") ? "Waiting on activation" : getStatus(body);
  }

  private convertHistoryListToViewModel(historyList: OrderHistoryList): OrderHistoryViewModel[] {
    return historyList.list.map((item) => this.convertHistoryObjectToViewModel(item));
  }

  private convertHistoryObjectToViewModel(history: OrderHistory): OrderHistoryViewModel {
    return {
      status: history.status.value,
      timestamp: history.timestamp.value,
      activation: history.activation ? history.activation.value : undefined
    };
  }
}
