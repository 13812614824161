import IUploadOfferImageFileGateway from "../../../../domain/modules/offer/gateway/IUploadOfferImageFileGateway";
import OfferImageFilePathFactory from "../../../../domain/modules/offer/useCase/factory/OfferImageFilePathFactory";
import OfferImageFile from "../../../../domain/modules/offer/valueObject/OfferImageFile";
import OfferImageFilePresentation from "../../../../gui/presenter/offer/presentation/OfferImageFilePresentation";
import { OfferImageFilePathViewModel } from "../../../../gui/presenter/offer/viewModel/OfferImageFilePathViewModel";
import { ITokenRepository } from "../../tokenRepository/ITokenRepository";
import IOfferRepository from "../IOfferRepository";

export class GetOrdersGatewayError extends Error {
  constructor(message: string) {
    super(`[GetOrdersGateway] Error - ${message}`);
  }
}

export default class UploadOfferImageFileGateway implements IUploadOfferImageFileGateway {
  private imageFilePresentation: OfferImageFilePresentation;

  constructor(private _offerRepository: IOfferRepository, private _tokenRepository: ITokenRepository) {
    this.imageFilePresentation = new OfferImageFilePresentation();
  }

  public async uploadOfferImageFile(
    images: OfferImageFile,
    oldPaths: OfferImageFilePathViewModel
  ): Promise<OfferImageFilePathViewModel> {
    try {
      const token = this._tokenRepository.getStoredToken();
      const utility = this._tokenRepository.decodeToken(token).utility.value;
      const offerImageFilePaths = await this._offerRepository.uploadOfferImageFile(images, utility);

      return this.imageFilePresentation.present(offerImageFilePaths, OfferImageFilePathFactory.create(oldPaths));
    } catch (err: any) {
      throw new GetOrdersGatewayError(err.message);
    }
  }
}
