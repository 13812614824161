import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import Customer from "../../../../domain/modules/customer/entity/Customer";
import IGetCustomerGateway from "../../../../domain/modules/customer/gateway/IGetCustomerGateway";
import ICustomerRepository from "../ICustomerRepository";
import IOrderRepository from "../../orderRepository/IOrderRepository";
import OrderList from "../../../../domain/modules/order/valueObject/OrderList";

export class GetCustomerGatewayError extends Error {
    constructor(message: string) {
        super(`[GetCustomerGateway] Error - ${message}`)
    }
}

export default class GetCustomerGateway implements IGetCustomerGateway {
    constructor(
        private _customerRepository: ICustomerRepository,
        private _orderRepository: IOrderRepository
    ) { }

    public async getCustomer(id: StringId): Promise<Customer> {
        try {
            return await this._customerRepository.getCustomer(id);
        } catch (err: any) {
            throw new GetCustomerGatewayError(err.message);
        }
    }

    public async getOrdersForCustomer(customerId: StringId): Promise<OrderList> {
        try {
            return await this._orderRepository.getOrdersForCustomer(customerId);
        } catch (err: any) {
            throw new GetCustomerGatewayError(err.message);
        }
    }
}