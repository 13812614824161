import NetworkController from "../../network/NetworkController";
import ICustomerDataSource, { CustomerDTO, EnergyInfoDTO } from "./ICustomerDataSource";

export class CustomerDataSourceError extends Error {
  constructor(message: string) {
    super(`[CustomerDataSource] Error - ${message}`);
  }
}

export default class CustomerDataSource implements ICustomerDataSource {
  constructor(private nwc: NetworkController) {}

  async getCustomers(): Promise<CustomerDTO[]> {
    try {
      const customers: any = await this.nwc.request({
        url: "/utility-app/customers",
        method: "GET",
        useToken: true
      });
      return customers.data;
    } catch (error: any) {
      throw new CustomerDataSourceError(`[getCustomers] - ${error.message}`);
    }
  }

  async getCustomer(id: string): Promise<CustomerDTO> {
    try {
      const customer: any = await this.nwc.request({
        url: `/utility-app/customers/${id}`,
        method: "GET",
        useToken: true
      });
      return customer.data;
    } catch (error: any) {
      throw new CustomerDataSourceError(`[getCustomer] - ${error.message}`);
    }
  }

  async getCustomerEnergyInfo(customerId: string): Promise<EnergyInfoDTO> {
    try {
      const customer: any = await this.nwc.request({
        url: `/utility-app/customers/${customerId}/energy-profile`,
        method: "GET",
        useToken: true
      });
      return customer.data;
    } catch (error: any) {
      throw new CustomerDataSourceError(`[getCustomerEnergyInfo] - ${error.message}`);
    }
  }

  async setMeterId(mainMeter: string, obisCode: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/measuring-points/${mainMeter}/set-meter-id`,
        method: "PUT",
        useToken: true,
        data: { meterId: obisCode }
      });
    } catch (error: any) {
      throw new CustomerDataSourceError(`[setMeterId] - ${error.message}`);
    }
  }
}
