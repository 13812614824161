import NotEmptyString from "../../../domain/base/valueObject/NotEmptyString";
import NumericId from "../../../domain/base/valueObject/uniqueEntityID/NumericId";
import Offer from "../../../domain/modules/offer/entity/Offer";
import { CreateOfferInputs } from "../../../domain/modules/offer/useCase/processor/CreateOfferInputProcessor";
import OfferImageFile from "../../../domain/modules/offer/valueObject/OfferImageFile";
import OfferImageFilePath from "../../../domain/modules/offer/valueObject/OfferImageFilePath";
import OfferList from "../../../domain/modules/offer/valueObject/OfferList";
import OfferImagePresentation from "../../../gui/presenter/offer/presentation/OfferImagePresentation";
import OfferPresentation from "../../../gui/presenter/offer/presentation/OfferPresentation";
import { OfferImageViewModel } from "../../../gui/presenter/offer/viewModel/OfferImageViewModel";
import { OfferViewModel } from "../../../gui/presenter/offer/viewModel/OfferViewModel";
import IOfferDataSource, { FileDTO } from "../../dataSource/offerDataSource/IOfferDataSource";
import IOfferMapperFactory from "./factory/IOfferMapperFactory";
import IOfferRepository from "./IOfferRepository";

export class OfferRepositoryError extends Error {
  constructor(message: string) {
    super(`[OfferRepository] Error - ${message}`);
  }
}

export default class OfferRepository implements IOfferRepository {
  private presentation: OfferPresentation;
  private imagePresentation: OfferImagePresentation;

  constructor(private _dataSource: IOfferDataSource, private _mapperFactory: IOfferMapperFactory) {
    this.presentation = new OfferPresentation();
    this.imagePresentation = new OfferImagePresentation();
  }

  async getOffer(id: NumericId): Promise<OfferViewModel> {
    try {
      const offer = this._mapperFactory.getOfferMapper().map(await this._dataSource.getOffer(id.getId()));
      return this.presentation.map(offer);
    } catch (err: any) {
      throw new OfferRepositoryError(`[getOffer] - ${err.message}`);
    }
  }

  async getOffers(): Promise<OfferList> {
    try {
      const offers = await this._dataSource.getOffers();
      const offersMap = offers.map((o) => this._mapperFactory.getOfferMapper().map(o));
      return OfferList.create(offersMap);
    } catch (err: any) {
      throw new OfferRepositoryError(`[getOffers] - ${err.message}`);
    }
  }

  async getOfferImages(selectedImg: NotEmptyString): Promise<OfferImageViewModel[]> {
    try {
      const imgType = selectedImg.value === "img1" ? "headers" : "descriptions";
      const offersData = await this._dataSource.getOfferImages(imgType);
      const offers = offersData.map((image) => {
        return this._mapperFactory.getOfferImagesMapper().map(image);
      });
      return offers.map((image) => this.imagePresentation.map(image));
    } catch (err: any) {
      throw new OfferRepositoryError(`[getOfferImages] - ${err.message}`);
    }
  }

  async createOffer(offer: CreateOfferInputs): Promise<void> {
    try {
      const offerEditData = this._mapperFactory.getOfferEditDataMapper().map(offer);
      await this._dataSource.createOffer(offerEditData);
    } catch (err: any) {
      throw new OfferRepositoryError(`[createOffer] - ${err.message}`);
    }
  }

  async activateOffer(id: NumericId): Promise<void> {
    try {
      await this._dataSource.activateOffer(id.getId());
    } catch (err: any) {
      throw new OfferRepositoryError(`[activateOffer] - ${err.message}`);
    }
  }

  async closeOffer(id: NumericId): Promise<void> {
    try {
      await this._dataSource.closeOffer(id.getId());
    } catch (err: any) {
      throw new OfferRepositoryError(`[closeOffer] - ${err.message}`);
    }
  }

  async updateOffer(offer: Offer): Promise<Offer> {
    try {
      const offerId = offer.id.getId();
      const offerEditData = this._mapperFactory.getOfferEditDataMapper().map(offer);
      await this._dataSource.updateOffer(offerId, offerEditData);
      return offer;
    } catch (err: any) {
      throw new OfferRepositoryError(`[updateOffer] - ${err.message}`);
    }
  }

  async uploadOfferImageFile(images: OfferImageFile, utility: string): Promise<OfferImageFilePath> {
    try {
      const files: FileDTO[] = [];

      Object.entries(images.props).forEach(([key, value]) => {
        Object.entries(value.props).forEach(([_key, language]) => {
          if (language !== undefined) {
            if (files.length === 0) {
              files.push({
                type: key === "img1" ? "header-image" : "description-image",
                file: language as File
              });
            } else if (!files.some((img) => img.file.name === (language as File).name)) {
              files.push({
                type: key === "img1" ? "header-image" : "description-image",
                file: language as File
              });
            }
          }
        });
      });

      const newPaths = await this._dataSource.uploadOfferImageFile(files, utility);

      const offerImageMapped = this._mapperFactory.getOfferImageFilePathMapper().map({
        newPaths,
        images
      });

      return offerImageMapped;
    } catch (err: any) {
      throw new OfferRepositoryError(`[uploadOfferImageFile] - ${err.message}`);
    }
  }
}
