import Container from "@mui/material/Container/Container";
import { MultilingualFileViewModel } from "../../../presenter/offer/viewModel/MultilingualFileViewModel";
import getDefaultMultilingualStringViewModel, {
  MultilingualStringViewModel
} from "../../../presenter/offer/viewModel/MultilingualStringViewModel";
import getDefaultOfferImageFileViewModel, {
  OfferImageFileViewModel
} from "../../../presenter/offer/viewModel/OfferImageFileViewModel";
import getDefaultOfferViewModel, { OfferViewModel } from "../../../presenter/offer/viewModel/OfferViewModel";

export const languageOptions = [
  {
    name: "English",
    value: "en"
  },
  {
    name: "German",
    value: "de"
  },
  {
    name: "French",
    value: "fr"
  },
  {
    name: "Italian",
    value: "it"
  }
];

export const categoryOptions = [
  {
    name: "Hardware",
    value: "HARDWARE_PRODUCT"
  },
  {
    name: "Energy Product",
    value: "ENERGY_PRODUCT"
  }
];

export const dialogStatusTextToClose = `This action will result in changing the offer's status from ACTIVE to CLOSED and it is irreversible.
                                  Once the offer is closed, it cannot be edited anymore and it will not be visible to mobile users.`;

export const dialogStatusTextToActivateESP = `There can only be ONE active ESP offer at a time. 
                                          This action will result in changing the offer's status from DRAFT to ACTIVE and it is irreversible.
                                          Also, if you activate this offer, the previous one will be closed.`;

export const dialogStatusTextToActivate = `This action will result in changing the offer's status from DRAFT to ACTIVE and it is irreversible.`;

export const activeOfferEditingWarning = {
  prefix: `You are editing an active offer. Make sure not to change the offer price and conditions! If you would like to make a new offer for your customers use the `,
  suffix: ` option instead.`
};

export const termsErrorView = (
  <Container
    disableGutters
    maxWidth={false}
    sx={{
      color: "#DE2C62"
    }}
  >
    Please note that you've read and agreed with the terms of this action.
  </Container>
);

export const defaultTextFieldControl = {
  en: false,
  de: false,
  fr: false,
  it: false
};

export const checkImageUpload = (images: MultilingualFileViewModel) => {
  return Object.entries(images).filter((lang) => lang[1] !== undefined).length > 0;
};

export type LanguageControl = {
  en: boolean;
  de: boolean;
  it: boolean;
  fr: boolean;
};

export type ResetType =
  | MultilingualStringViewModel
  | OfferImageFileViewModel
  | LanguageControl
  | OfferViewModel
  | Changeable;

const getDefaultLanguageControl = (): LanguageControl => {
  return {
    en: false,
    de: false,
    it: false,
    fr: false
  };
};

export const createDialogText = "This action will result in creating a new offer.";

export type Changeable = {
  category: string;
  name: MultilingualStringViewModel;
  header: MultilingualStringViewModel;
  img1: MultilingualStringViewModel;
  img2: MultilingualStringViewModel;
  description: MultilingualStringViewModel;
  details: MultilingualStringViewModel;
};

export const resetState: {
  [key: string]: LanguageControl | OfferImageFileViewModel | OfferViewModel | Changeable;
} = {
  languageControl: getDefaultLanguageControl(),
  file: getDefaultOfferImageFileViewModel(),
  initialValues: getDefaultOfferViewModel(),
  changeable: {
    category: "HARDWARE_PRODUCT",
    name: getDefaultMultilingualStringViewModel(),
    header: getDefaultMultilingualStringViewModel(),
    img1: getDefaultMultilingualStringViewModel(),
    img2: getDefaultMultilingualStringViewModel(),
    description: getDefaultMultilingualStringViewModel(),
    details: getDefaultMultilingualStringViewModel()
  }
};

export const getDisplayLanguage = (name: MultilingualStringViewModel) => {
  if (name.en !== "") return "en";
  else if (name.de !== "") return "de";
  else if (name.fr !== "") return "fr";
  else return "it";
};

export default getDefaultLanguageControl;
