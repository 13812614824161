export type MultilingualFileViewModel = { [key: string]: File | undefined };

const getDefaultMultilingualFileViewModel = (): MultilingualFileViewModel => {
  return {
    en: undefined,
    de: undefined,
    fr: undefined,
    it: undefined
  };
};

export default getDefaultMultilingualFileViewModel;
