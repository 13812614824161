import NumericId from "../../../../domain/base/valueObject/uniqueEntityID/NumericId";
import Offer from "../../../../domain/modules/offer/entity/Offer";
import OfferCategory from "../../../../domain/modules/offer/valueObject/OfferCategory";
import OfferStatus from "../../../../domain/modules/offer/valueObject/OfferStatus";
import MultilingualString from "../../../../domain/modules/MultilingualString";
import { MultilingualStringDTO, OfferDTO } from "../../../dataSource/offerDataSource/IOfferDataSource";
import IMapper from "../../../IMapper";

export class OfferMapperError extends Error {
  constructor(message: string) {
    super(`[OfferMapper] Error - ${message}`);
  }
}

export default class OfferMapper implements IMapper<OfferDTO, Offer> {
  map(input: OfferDTO): Offer {
    try {
      const { category, state, name, header, img1, img2, details, description, id } = input;

      return new Offer(
        {
          category: OfferCategory.create(category),
          status: OfferStatus.create(state),
          name: this.createMultilingualData(name),
          header: this.createMultilingualData(header),
          img1: this.createMultilingualData(img1),
          img2: this.createMultilingualData(img2),
          description: this.createMultilingualData(description),
          details: this.createMultilingualData(details)
        },
        NumericId.create(id)
      );
    } catch (err: any) {
      throw new OfferMapperError(err.message);
    }
  }

  private createMultilingualData(input: MultilingualStringDTO) {
    return MultilingualString.create(input.en, input.de, input.fr, input.it);
  }
}
