import Offer from "../../../../domain/modules/offer/entity/Offer";
import { CreateOfferInputs } from "../../../../domain/modules/offer/useCase/processor/CreateOfferInputProcessor";
import OfferImage from "../../../../domain/modules/offer/valueObject/OfferImage";
import OfferImageFilePath from "../../../../domain/modules/offer/valueObject/OfferImageFilePath";
import {
  OfferDTO,
  OfferEditData
} from "../../../dataSource/offerDataSource/IOfferDataSource";
import IMapper from "../../../IMapper";
import OfferEditDataMapper from "../mapper/OfferEditDataMapper";
import OfferImageFilePathMapper, {
  OfferImageFileMap
} from "../mapper/OfferImageFilePathMapper";
import OfferImageMapper from "../mapper/OfferImageMapper";
import OfferMapper from "../mapper/OfferMapper";
import IOfferMapperFactory from "./IOfferMapperFactory";

export default class OfferMapperFactory implements IOfferMapperFactory {
  getOfferMapper(): IMapper<OfferDTO, Offer> {
    return new OfferMapper();
  }

  getOfferEditDataMapper(): IMapper<Offer | CreateOfferInputs, OfferEditData> {
    return new OfferEditDataMapper();
  }

  getOfferImagesMapper(): IMapper<string, OfferImage> {
    return new OfferImageMapper();
  }

  getOfferImageFilePathMapper(): IMapper<OfferImageFileMap, OfferImageFilePath> {
    return new OfferImageFilePathMapper();
  }
}
