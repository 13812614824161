import NotEmptyString from "../base/valueObject/NotEmptyString";
import { ValueObject } from "../base/valueObject/ValueObject";

export class ZipCodeError extends Error {
    constructor(message: string) {
        super(`[ZipCode] Error - ${message}`);
    }
}

interface ZipCodeProps {
    code: NotEmptyString;
}

export default class ZipCode extends ValueObject<ZipCodeProps> {

    get value(): string {
        return this.props.code.value;
    }

    private constructor(props: ZipCodeProps) {
        super(props);
    }

    public static create(zipCode: string): ZipCode {
        let code: NotEmptyString;
        try {
            code = NotEmptyString.create(zipCode);
        }
        catch (error: any) {
            throw new ZipCodeError(`Provided value is empty string.`)
        }
        if (!ZipCode.isValid(code))
            throw new ZipCodeError(`ZipCode must contains only digits. Provided ${code.value}`);
        return new ZipCode({ code });
    }

    private static isValid(code: NotEmptyString): boolean {
        return !isNaN(parseInt(code.value));
    }

    public equals(vo: ValueObject<ZipCodeProps>): boolean {
        return vo.props.code.equals(this.props.code);
    }
}