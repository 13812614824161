import { EventTemplateEditData } from "../../dataSource/eventDataSource/IEventDataSource";

const subtractHours = (date: Date, hours: number) => {
  date.setHours(date.getHours() - hours);
  date.setMinutes(0);

  return date;
};

export type EventTemplateInputsModel = {
  field:
    | "frequency"
    | "type"
    | "title"
    | "shortDescription"
    | "detailedDescription"
    | "status"
    | "eventStartDate"
    | "eventEndDate"
    | "relevantNotificationDate";
  value: string | Date;
};

export type EventViewModel = {
  id: string;
  state: string;
  template: string;
  frequency: string;
  type: string;
  utility: string;
  eventStartDate: string;
  eventEndDate: string;
  districts: string[];
  title: string;
  shortDescription: string;
  detailedDescription: string;
  createdAt: string;
};

export type EventTemplateViewModel = {
  id: string;
  utility: string;
  frequency: string;
  type: string;
  eventStartDate: string;
  eventEndDate: string;
  relevantNotificationDate: string;
  title: string;
  shortDescription: string;
  detailedDescription: string;
  districts: string[];
  createdAt: string;
  status: string;
};

export const defaultEventTemplateViewModel = () => {
  const value = new Date();
  value.setMinutes(0);
  value.setSeconds(0);
  value.setMilliseconds(0);

  const hoursAhead = (hours: number) => value.getTime() + hours * 60 * 60 * 1000;

  return {
    id: "0",
    status: "single",
    utility: "",
    eventStartDate: new Date(hoursAhead(2)).toISOString(),
    eventEndDate: new Date(hoursAhead(3)).toISOString(),
    relevantNotificationDate: subtractHours(new Date(hoursAhead(2)), 1).toISOString(), // notification goes before eventStartDate, by default 1h
    frequency: "ONCE",
    type: "MALFUNCTION_OF_SUPPLY",
    districts: [],
    title: "",
    shortDescription: "",
    detailedDescription: "",
    createdAt: value.toISOString(),
  };
};

export const defaultEventViewModel = () => {
  const value = new Date();
  value.setSeconds(0);
  value.setMilliseconds(0);
  return {
    id: "0",
    state: "EXPIRED",
    utility: "",
    template: "",
    eventStartDate: value.toISOString(),
    eventEndDate: value.toISOString(),
    frequency: "ONCE",
    type: "MALFUNCTION_OF_SUPPLY",
    districts: [],
    title: "",
    shortDescription: "",
    detailedDescription: "",
    createdAt: value.toISOString(),
  };
};

export type DistrictTagViewModel = {
  value: string;
  count: number;
};

export type EventStatisticsViewModel = {
  districts: DistrictTagViewModel[];
  totalRecipients: number;
  totalOpened: number;
  clickToRate: string;
};

export default interface IEventRepository {
  createEventTemplate(eventTemplate: EventTemplateEditData): Promise<void>;
  updateEventTemplate(id: string, eventTemplate: EventTemplateEditData): Promise<void>;
  deleteEventTemplate(id: string): Promise<void>;

  getEventMagnitude(districts: string[], type: string): Promise<DistrictTagViewModel[]>;
  getEventTemplate(id: string): Promise<EventTemplateViewModel>;
  getEventTemplates(): Promise<EventTemplateViewModel[]>;
  getHistoryEvents(from: string, to: string): Promise<EventViewModel[]>;
  getEventStatistics(id: string): Promise<EventStatisticsViewModel>;
  getLiveEventsForTemplate(templateId: string): Promise<EventViewModel[]>;
  getEvent(id: string): Promise<EventViewModel>;
}
