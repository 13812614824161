import { ValueObject } from "../base/valueObject/ValueObject";

export class MultilingualStringError extends Error {
    constructor(message: string) {
        super(`[MultilingualStringError] Error - ${message}`);
    }
}

interface MultilingualStringProps {
    en: string;
    de: string;
    fr: string;
    it: string;
}

export default class MultilingualString extends ValueObject<MultilingualStringProps> {
    get en(): string {
        return this.props.en;
    }

    get de(): string {
        return this.props.de;
    }

    get fr(): string {
        return this.props.fr;
    }

    get it(): string {
        return this.props.it;
    }

    private constructor(props: MultilingualStringProps) {
        super(props);
    }

    public static create(en: string, de: string, fr: string, it: string): MultilingualString {
        if (!MultilingualString.validate(en)) {
            throw new MultilingualStringError("English multilingual string is not valid.");
        }
        if (!MultilingualString.validate(de)) {
            throw new MultilingualStringError("German multilingual string is not valid.");
        }
        if (!MultilingualString.validate(fr)) {
            throw new MultilingualStringError("French multilingual string is not valid.");
        }
        if (!MultilingualString.validate(it)) {
            throw new MultilingualStringError("Italian multilingual string is not valid.");
        }
        else {
            return new MultilingualString({ en, de, fr, it })
        }
    }

    private static validate(value: string): boolean {
        if (value === undefined || value === null)
            return false;
        return true;
    }

    public equals(vo: ValueObject<MultilingualStringProps>): boolean {
        return vo.props.en === this.props.en &&
            vo.props.de === this.props.de &&
            vo.props.fr === this.props.fr &&
            vo.props.it === this.props.it
    }

    public language(language: string): string {
        switch (language) {
            case 'de':
                return this.props.de
            case 'fr':
                return this.props.fr
            case 'it':
                return this.props.de
            default:
                return this.props.en
        }
    }
}
