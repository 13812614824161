import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomerViewModel } from "../../presenter/customer/viewModel/CustomerViewModel";

interface CustomerListState {
  customerList: CustomerViewModel[];
  loading: boolean;
  pageSize: number;
  pageNumber: number;
  error?: string;
}

const initialState: CustomerListState = {
  customerList: [],
  pageSize: 10,
  pageNumber: 0,
  loading: false
};

export const customerListSlice = createSlice({
  name: "customerList",
  initialState,
  reducers: {
    customersLoaded: (state, { payload }: PayloadAction<{ customers: CustomerViewModel[] }>) => {
      state.loading = false;
      state.customerList = payload.customers;
    },
    pageNumberSet: (state, { payload }: PayloadAction<{ value: number }>) => {
      state.pageNumber = payload.value;
    },
    pageSizeSet: (state, { payload }: PayloadAction<{ value: number }>) => {
      state.pageSize = payload.value;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState
  }
});

export const {
  customersLoaded,
  errorHappened,
  requestStarted,
  requestFinished,
  viewUnloaded,
  pageNumberSet,
  pageSizeSet
} = customerListSlice.actions;
