import ICheckAuthGateway from "../domain/modules/auth/gateway/ICheckAuthGateway";
import IForgotPasswordGateway from "../domain/modules/auth/gateway/IForgotPasswordGateway";
import ISignInGateway from "../domain/modules/auth/gateway/ISignInGateway";
import ISignOutGateway from "../domain/modules/auth/gateway/ISignOutGateway";
import IGetCustomerEnergyInfoGateway from "../domain/modules/customer/gateway/IGetCustomerEnergyInfoGateway";
import IGetCustomerGateway from "../domain/modules/customer/gateway/IGetCustomerGateway";
import IGetCustomersGateway from "../domain/modules/customer/gateway/IGetCustomersGateway";
import ISetMeterIdGateway from "../domain/modules/customer/gateway/ISetMeterIdGateway";
import ICreateEventTemplateGateway from "../domain/modules/event/gateway/ICreateEventTemplateGateway";
import IDeleteEventTemplateGateway from "../domain/modules/event/gateway/IDeleteEventTemplateGateway";
import IGetEventMagnitudeGateway from "../domain/modules/event/gateway/IGetEventMagnitudeGateway";
import IGetEventStatisticsGateway from "../domain/modules/event/gateway/IGetEventStatistics";
import IGetEventTemplateGateway from "../domain/modules/event/gateway/IGetEventTemplateGateway";
import IGetHistoryEventsGateway from "../domain/modules/event/gateway/IGetHistoryEventsGateway";
import IUpdateEventTemplateGateway from "../domain/modules/event/gateway/IUpdateEventTemplateGateway";
import IGetLiveEventsForTemplateGateway from "../domain/modules/event/gateway/IGetLiveEventsForTemplateGateway";
import ICreateMessageGateway from "../domain/modules/message/gateway/ICreateMessageGateway";
import IGetMessageGateway from "../domain/modules/message/gateway/IGetMessageGateway";
import IGetMessagesGateway from "../domain/modules/message/gateway/IGetMessagesGateway";
import IGetMyProfileGateway from "../domain/modules/myProfile/gateway/GetMyProfileGateway";
import IActivateOfferGateway from "../domain/modules/offer/gateway/IActivateOfferGateway";
import ICloseOfferGateway from "../domain/modules/offer/gateway/ICloseOfferGateway";
import ICreateOfferGateway from "../domain/modules/offer/gateway/ICreateOfferGateway";
import IGetOfferGateway from "../domain/modules/offer/gateway/IGetOfferGateway";
import IGetOfferImagesGateway from "../domain/modules/offer/gateway/IGetOfferImagesGateway";
import IGetOffersGateway from "../domain/modules/offer/gateway/IGetOffersGateway";
import IUpdateOfferGateway from "../domain/modules/offer/gateway/IUpdateOfferGateway";
import IUploadOfferImageFileGateway from "../domain/modules/offer/gateway/IUploadOfferImageFileGateway";
import IChangeOrderStatusGateway from "../domain/modules/order/gateway/IChangeOrderStatusGateway";
import ICompleteOrderGateway from "../domain/modules/order/gateway/ICompleteOrderGateway";
import IDeactivateOrderGateway from "../domain/modules/order/gateway/IDeactivateOrderGateway";
import IDeclineOrderGateway from "../domain/modules/order/gateway/IDeclineOrderGateway";
import IDeleteOrderGateway from "../domain/modules/order/gateway/IDeleteOrderGateway";
import IGetOrderGateway from "../domain/modules/order/gateway/IGetOrderGateway";
import IGetOrdersGateway from "../domain/modules/order/gateway/IGetOrdersGateway";
import ISelectConfigurationsGateway from "../domain/modules/order/gateway/ISelectConfigurationsGateway";
import IUpdateOrderNoteGateway from "../domain/modules/order/gateway/IUpdateOrderNoteGateway";
import IAddDistrictGateway from "../domain/modules/settings/gateway/IAddDistrictGateway";
import IAddNotificationSubscriberGateway from "../domain/modules/settings/gateway/IAddNotificationSubscriberGateway";
import IAddZipCodeGateway from "../domain/modules/settings/gateway/IAddZipCodeGateway";
import IDeleteDistrictGateway from "../domain/modules/settings/gateway/IDeleteDistrictGateway";
import IDeleteNotificationSubscriberGateway from "../domain/modules/settings/gateway/IDeleteNotificationSubscriber";
import IDeleteZipCodeGateway from "../domain/modules/settings/gateway/IDeleteZipCodeGateway";
import IEditDistrictGateway from "../domain/modules/settings/gateway/IEditDistrictGateway";
import IGetDistrictsGateway from "../domain/modules/settings/gateway/IGetDistrictsGateway";
import IGetSettingsGateway from "../domain/modules/settings/gateway/IGetSettingsGateway";
import IGetUsersGateway from "../domain/modules/user/gateway/IGetUsersGateway";
import RepositoryFactory from "./RepositoryFactory";
import IGetEventGateway from "../domain/modules/event/gateway/IGetEventGateway";
import IGetEventTemplatesGateway from "../domain/modules/event/gateway/IGetEventTemplatesGateway";
import ICreateOrderGateway from "../domain/modules/order/gateway/ICreateOrderGateway";

export default class GatewayFactory {
  constructor(private repositoryFactory: RepositoryFactory) {}

  getSignInGateway(): ISignInGateway {
    return this.repositoryFactory.getTokenRepository();
  }

  getCheckAuthGateway(): ICheckAuthGateway {
    return this.repositoryFactory.getTokenRepository();
  }

  getSignOutGateway(): ISignOutGateway {
    return this.repositoryFactory.getTokenRepository();
  }

  getGetOrderGateway(): IGetOrderGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getGetOrdersGateway(): IGetOrdersGateway {
    return this.repositoryFactory.getGetOrdersGateway();
  }

  getGetOfferGateway(): IGetOfferGateway {
    return this.repositoryFactory.getOfferRepository();
  }

  getChangeOrderStatusGateway(): IChangeOrderStatusGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getCompleteOrderGateway(): ICompleteOrderGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getDeclineOrderGateway(): IDeclineOrderGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getUpdateOrderNoteGateway(): IUpdateOrderNoteGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getGetOffersGateway(): IGetOffersGateway {
    return this.repositoryFactory.getOfferRepository();
  }

  getGetOfferImagesGateway(): IGetOfferImagesGateway {
    return this.repositoryFactory.getOfferRepository();
  }

  getCreateOfferGateway(): ICreateOfferGateway {
    return this.repositoryFactory.getOfferRepository();
  }

  getUpdateOfferGateway(): IUpdateOfferGateway {
    return this.repositoryFactory.getOfferRepository();
  }

  getUploadOfferImageFileGateway(): IUploadOfferImageFileGateway {
    return this.repositoryFactory.getUploadOfferImagesGateway();
  }

  getGetCustomerGateway(): IGetCustomerGateway {
    return this.repositoryFactory.getGetCustomerGateway();
  }

  getGetCustomersGateway(): IGetCustomersGateway {
    return this.repositoryFactory.getCustomerRepository();
  }

  getGetMyProfileGateway(): IGetMyProfileGateway {
    return this.repositoryFactory.getTokenRepository();
  }

  getActivateOfferGateway(): IActivateOfferGateway {
    return this.repositoryFactory.getOfferRepository();
  }

  getCloseOfferGateway(): ICloseOfferGateway {
    return this.repositoryFactory.getOfferRepository();
  }

  getForgotPasswordGateway(): IForgotPasswordGateway {
    return this.repositoryFactory.getTokenRepository();
  }

  getDeleteOrderGateway(): IDeleteOrderGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getGetCustomerEnergyInfoGateway(): IGetCustomerEnergyInfoGateway {
    return this.repositoryFactory.getCustomerRepository();
  }

  getDeactivateOrderGateway(): IDeactivateOrderGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getSetMeterIdGateway(): ISetMeterIdGateway {
    return this.repositoryFactory.getCustomerRepository();
  }

  getGetUsersGateway(): IGetUsersGateway {
    return this.repositoryFactory.getUsersRepository();
  }

  getGetMessagesGateway(): IGetMessagesGateway {
    return this.repositoryFactory.getMessageRepository();
  }

  getGetMessageGateway(): IGetMessageGateway {
    return this.repositoryFactory.getMessageRepository();
  }

  getCreateMessageGateway(): ICreateMessageGateway {
    return this.repositoryFactory.getMessageRepository();
  }

  getSelectConfigurationsGateway(): ISelectConfigurationsGateway {
    return this.repositoryFactory.getOrderRepository();
  }

  getGetSettingsGateway(): IGetSettingsGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getDeleteZipCodeGateway(): IDeleteZipCodeGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getDeleteSubscriberGateway(): IDeleteNotificationSubscriberGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getAddZipCodeGateway(): IAddZipCodeGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getAddNotificationSubscriberGateway(): IAddNotificationSubscriberGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getGetDistrictsGateway(): IGetDistrictsGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getAddDistrictGateway(): IAddDistrictGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getEditDistrictGateway(): IEditDistrictGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getDeleteDistrictGateway(): IDeleteDistrictGateway {
    return this.repositoryFactory.getSettingsRepository();
  }

  getGetEventTemplateGateway(): IGetEventTemplateGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getGetEventTemplatesGateway(): IGetEventTemplatesGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getGetHistoryEventsGateway(): IGetHistoryEventsGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getGetEventStatisticsGateway(): IGetEventStatisticsGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getCreateEventStatisticsGateway(): ICreateEventTemplateGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getUpdateEventStatisticsGateway(): IUpdateEventTemplateGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getDeleteEventStatisticsGateway(): IDeleteEventTemplateGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getGetEventMagnitudeGateway(): IGetEventMagnitudeGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getGetLiveEventsForTemplateGateway(): IGetLiveEventsForTemplateGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getGetEventGateway(): IGetEventGateway {
    return this.repositoryFactory.getEventRepository();
  }

  getCreateOrderGateway(): ICreateOrderGateway {
    return this.repositoryFactory.getOrderRepository();
  }
}
