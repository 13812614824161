import { HttpData } from "../config/Config";
import IGetCustomerGateway from "../domain/modules/customer/gateway/IGetCustomerGateway";
import IGetCustomersGateway from "../domain/modules/customer/gateway/IGetCustomersGateway";
import IUploadOfferImageFileGateway from "../domain/modules/offer/gateway/IUploadOfferImageFileGateway";
import IGetOrdersGateway from "../domain/modules/order/gateway/IGetOrdersGateway";
import CustomerDataSource from "../infrastructure/dataSource/customerDataSource/CustomerDataSource";
import EventDataSource from "../infrastructure/dataSource/eventDataSource/EventDataSource";
import MessageDataSource from "../infrastructure/dataSource/messageDataSource/MessageDataSource";
import OfferDataSource from "../infrastructure/dataSource/offerDataSource/OfferDataSource";
import OrderDataSource from "../infrastructure/dataSource/orderDataSource/OrderDataSource";
import { SettingsDataSource } from "../infrastructure/dataSource/settingsDataSource/SettingsDataSource";
import TokenDataSource from "../infrastructure/dataSource/tokenDataSource/TokenDataSource";
import UserDataSource from "../infrastructure/dataSource/userDataSource/UserDataSource";
import LocalStorage from "../infrastructure/LocalStorage";
import NetworkController from "../infrastructure/network/NetworkController";
import CustomerRepository from "../infrastructure/repository/customerRepository/CustomerRepository";
import CustomerMapperFactory from "../infrastructure/repository/customerRepository/factory/CustomerMapperFactory";
import GetCustomerGateway from "../infrastructure/repository/customerRepository/gateway/GetCustomerGateway";
import ICustomerRepository from "../infrastructure/repository/customerRepository/ICustomerRepository";
import EventRepository from "../infrastructure/repository/eventRepository/EventRepository";
import IEventRepository from "../infrastructure/repository/eventRepository/IEventRepository";
import IMessageRepository from "../infrastructure/repository/messageRepository/IMessageRepository";
import MessageRepository from "../infrastructure/repository/messageRepository/MessageRepository";
import MyProfileMapperFactory from "../infrastructure/repository/myProfileRepository/factory/MyProfileMapperFactory";
import OfferMapperFactory from "../infrastructure/repository/offerRepository/factory/OfferMapperFactory";
import UploadOfferImageFileGateway from "../infrastructure/repository/offerRepository/gateway/UploadOfferImageFileGateway";
import IOfferRepository from "../infrastructure/repository/offerRepository/IOfferRepository";
import OfferRepository from "../infrastructure/repository/offerRepository/OfferRepository";
import OrderMapperFactory from "../infrastructure/repository/orderRepository/factory/OrderMapperFactory";
import GetOrdersGateway from "../infrastructure/repository/orderRepository/gateway/GetOrdersGateway";
import IOrderRepository from "../infrastructure/repository/orderRepository/IOrderRepository";
import OrderRepository from "../infrastructure/repository/orderRepository/OrderRepository";
import ISettingsRepository from "../infrastructure/repository/settingsRepository/ISettingsRepository";
import SettingsRepository from "../infrastructure/repository/settingsRepository/SettingsRepository";
import TokenRepositoryMapperFactory from "../infrastructure/repository/tokenRepository/factory/TokenRepositoryMapperFactory";
import { ITokenRepository } from "../infrastructure/repository/tokenRepository/ITokenRepository";
import { TokenRepository } from "../infrastructure/repository/tokenRepository/TokenRepository";
import IUserRepository from "../infrastructure/repository/userRepository/IUserRepository";
import UserRepository from "../infrastructure/repository/userRepository/UserRepository";

export default class RepositoryFactory {
  constructor(private nwc: NetworkController, private storage: LocalStorage, private httpConfig: HttpData) {}

  getTokenRepository(): ITokenRepository {
    return new TokenRepository(
      new TokenDataSource(this.nwc, this.storage),
      new TokenRepositoryMapperFactory(),
      new MyProfileMapperFactory()
    );
  }

  getOrderRepository(): IOrderRepository {
    return new OrderRepository(new OrderDataSource(this.nwc), new OrderMapperFactory());
  }

  getGetOrdersGateway(): IGetOrdersGateway {
    return new GetOrdersGateway(
      new OrderRepository(new OrderDataSource(this.nwc), new OrderMapperFactory()),
      new CustomerRepository(new CustomerDataSource(this.nwc), new CustomerMapperFactory())
    );
  }

  getOfferRepository(): IOfferRepository {
    return new OfferRepository(new OfferDataSource(this.nwc, this.httpConfig), new OfferMapperFactory());
  }

  getCustomerRepository(): ICustomerRepository {
    return new CustomerRepository(new CustomerDataSource(this.nwc), new CustomerMapperFactory());
  }

  getUploadOfferImagesGateway(): IUploadOfferImageFileGateway {
    return new UploadOfferImageFileGateway(
      new OfferRepository(new OfferDataSource(this.nwc, this.httpConfig), new OfferMapperFactory()),
      new TokenRepository(
        new TokenDataSource(this.nwc, this.storage),
        new TokenRepositoryMapperFactory(),
        new MyProfileMapperFactory()
      )
    );
  }

  getGetCustomerGateway(): IGetCustomerGateway {
    return new GetCustomerGateway(
      new CustomerRepository(new CustomerDataSource(this.nwc), new CustomerMapperFactory()),
      new OrderRepository(new OrderDataSource(this.nwc), new OrderMapperFactory())
    );
  }

  getGetCustomersGateway(): IGetCustomersGateway {
    return new CustomerRepository(new CustomerDataSource(this.nwc), new CustomerMapperFactory());
  }

  getUsersRepository(): IUserRepository {
    return new UserRepository(new UserDataSource(this.nwc));
  }

  getMessageRepository(): IMessageRepository {
    return new MessageRepository(new MessageDataSource(this.nwc));
  }

  getSettingsRepository(): ISettingsRepository {
    return new SettingsRepository(new SettingsDataSource(this.nwc));
  }

  getEventRepository(): IEventRepository {
    return new EventRepository(new EventDataSource(this.nwc));
  }
}
