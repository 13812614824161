import { ValueObject } from "../base/valueObject/ValueObject";

interface TimestampsProps {
  value: string;
}

export default class Timestamp extends ValueObject<TimestampsProps> {
  get value(): string {
    return this.props.value;
  }

  private constructor(props: TimestampsProps) {
    super(props);
  }

  public static create(value: string): Timestamp {
    return new Timestamp({ value });
  }

  public equals(vo: ValueObject<TimestampsProps>): boolean {
    return vo.props.value === this.value;
  }
}
