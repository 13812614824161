import LocalStorage from "../../LocalStorage";
import NetworkController from "../../network/NetworkController";
import { MyProfileDTO } from "../myProfileDataSource/IMyProfileDataSource";
import ITokenDataSource, { TokenDTO } from "./ITokenDataSource";
import jwt_decode from "jwt-decode";

export class TokenDataSourceError extends Error {
  constructor(message: string) {
    super(`[TokenDataSource] Error - ${message}`);
  }
}

export default class TokenDataSource implements ITokenDataSource {
  constructor(private nwc: NetworkController, private storage: LocalStorage) {}

  async getToken(email: string, password: string): Promise<TokenDTO> {
    try {
      const data = new URLSearchParams();
      data.append("username", email);
      data.append("password", password);
      data.append("grant_type", "password");
      data.append("client_id", "web");

      const result: any = await this.nwc.request({
        url: "/utility-app/signin",
        method: "POST",
        data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });

      return {
        accessToken: result.data.access_token,
        refreshToken: result.data.refresh_token
      };
    } catch (error: any) {
      throw new TokenDataSourceError(`[getToken] - ${error.message}`);
    }
  }

  storeToken({ accessToken, refreshToken }: TokenDTO): void {
    this.storage.set("accessToken", accessToken);
    this.storage.set("refreshToken", refreshToken);
  }

  hasToken(): boolean {
    return this.storage.has("accessToken") && this.storage.has("refreshToken");
  }

  clearToken(): void {
    this.storage.remove("accessToken");
    this.storage.remove("refreshToken");
  }

  async checkToken(): Promise<void> {
    await this.nwc.request({
      url: "/utility-app/ping/protected",
      method: "GET",
      useToken: true
    });
  }

  getStoredToken(): TokenDTO {
    return {
      accessToken: this.storage.get("accessToken"),
      refreshToken: this.storage.get("refreshToken")
    };
  }

  decodeToken({ accessToken }: TokenDTO): MyProfileDTO {
    return jwt_decode(accessToken);
  }

  async signOut(token: TokenDTO): Promise<void> {
    try {
      const data = new URLSearchParams();
      data.append("client_id", "web");
      data.append("refresh_token", token.refreshToken);

      await this.nwc.request({
        url: "/utility-app/signout",
        method: "POST",
        useToken: true,
        data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
    } catch (error: any) {
      throw new TokenDataSourceError(`[signOut] - ${error.message}`);
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      const data = new URLSearchParams();
      data.append("email", email);

      await this.nwc.request({
        url: "/utility-app/forgot-password",
        method: "POST",
        data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
    } catch (err: any) {
      throw new TokenDataSourceError(`[forgotPassword] - ${err.message}`);
    }
  }
}
