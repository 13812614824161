import { OfferImageViewModel } from "../../presenter/offer/viewModel/OfferImageViewModel";
import getDefaultMultilingualStringViewModel, {
  MultilingualStringViewModel
} from "../../presenter/offer/viewModel/MultilingualStringViewModel";

import getDefaultLanguageControl, {
  Changeable,
  getDisplayLanguage,
  LanguageControl,
  resetState,
  ResetType
} from "../../page/offer/offerEditor/resources";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageViewModel, OfferInputsModel, OfferViewModel } from "../../presenter/offer/viewModel/OfferViewModel";
import { OfferImageFilePathViewModel } from "../../presenter/offer/viewModel/OfferImageFilePathViewModel";

interface OfferState {
  id: number;
  category: string;
  status: string;
  name: MultilingualStringViewModel;
  header: MultilingualStringViewModel;
  img1: MultilingualStringViewModel;
  img2: MultilingualStringViewModel;
  description: MultilingualStringViewModel;
  details: MultilingualStringViewModel;
  timeInterval: {
    startDate: string;
    endDate: string;
  };
  changeable: Changeable;
  loading: boolean;
  error?: string;
  themeLight: boolean;
  language: LanguageViewModel;
  images: OfferImageViewModel[];
  selectedImg: string;
  selectedImgUrl: MultilingualStringViewModel;
  loadingImages: boolean;
  errorImages?: string;
  filesUploaded: boolean;
  languageControl: LanguageControl;
  categoryChange: boolean;
  success: boolean;
  formRemoved: boolean;
}

const initialState: OfferState = {
  id: -1,
  name: getDefaultMultilingualStringViewModel(),
  header: getDefaultMultilingualStringViewModel(),
  category: "HARDWARE_PRODUCT",
  status: "draft",
  img1: getDefaultMultilingualStringViewModel(),
  img2: getDefaultMultilingualStringViewModel(),
  description: getDefaultMultilingualStringViewModel(),
  details: getDefaultMultilingualStringViewModel(),
  timeInterval: {
    startDate: "",
    endDate: ""
  },
  changeable: {
    category: "HARDWARE_PRODUCT",
    name: getDefaultMultilingualStringViewModel(),
    header: getDefaultMultilingualStringViewModel(),
    img1: getDefaultMultilingualStringViewModel(),
    img2: getDefaultMultilingualStringViewModel(),
    description: getDefaultMultilingualStringViewModel(),
    details: getDefaultMultilingualStringViewModel()
  },
  loading: false,
  themeLight: true,
  language: "en",
  images: [],
  selectedImg: "",
  selectedImgUrl: getDefaultMultilingualStringViewModel(),
  loadingImages: false,
  filesUploaded: false,
  languageControl: getDefaultLanguageControl(),
  categoryChange: false,
  success: false,
  formRemoved: false
};

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    offerLoaded: (state, { payload }: PayloadAction<{ offer: OfferViewModel }>) => {
      const newState = Object.entries(payload.offer).reduce((acc, curr) => {
        let key = curr[0];
        let val = curr[1];
        return { ...acc, [key]: val };
      }, {});

      return {
        ...state,
        loading: false,
        language: getDisplayLanguage(payload.offer.name),
        changeable: {
          ...state.changeable,
          ...newState
        },
        ...newState
      };
    },
    errorHappened: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
      state.languageControl = getDefaultLanguageControl();
      state.categoryChange = false;
      state.filesUploaded = false;
      state.changeable = {
        category: state.category,
        name: state.name,
        header: state.header,
        img1: state.img1,
        img2: state.img2,
        description: state.description,
        details: state.details
      };
    },
    requestStarted: (state, { payload }: PayloadAction<{ loadingImages: boolean }>) => {
      payload.loadingImages ? (state.loadingImages = true) : (state.loading = true);
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    filesUploadedFlagReset: (state) => {
      state.filesUploaded = false;
    },
    offerThemeChanged: (state) => {
      state.themeLight = !state.themeLight;
    },
    offerLanguageChanged: (state, { payload }: PayloadAction<LanguageViewModel>) => {
      state.language = payload;
    },
    offerCategoryChanged: (state, { payload }: PayloadAction<string>) => {
      state.changeable.category = payload;
    },
    offerImagesErrored: (state, { payload }: PayloadAction<{ errorMessage: string }>) => {
      state.errorImages = payload.errorMessage;
    },
    offerImagesLoaded: (state, { payload }: PayloadAction<{ images: OfferImageViewModel[] }>) => {
      state.loadingImages = false;
      state.images = payload.images;
    },
    selectedOfferImageReset: (state) => {
      state.selectedImg = "";
      state.selectedImgUrl = getDefaultMultilingualStringViewModel();
    },
    offerImageSelected: (
      state,
      { payload }: PayloadAction<{ selectedImg: string; selectedImgUrl: MultilingualStringViewModel }>
    ) => {
      state.selectedImg = payload.selectedImg;
      state.selectedImgUrl = payload.selectedImgUrl;
    },
    offerImageFileUploaded: (state, { payload }: PayloadAction<{ images: OfferImageFilePathViewModel }>) => {
      state.filesUploaded = true;
      state.changeable.img1 = payload.images.img1;
      state.changeable.img2 = payload.images.img2;
    },
    offerActivated: (state, { payload }: PayloadAction<{ status: string }>) => {
      state.status = payload.status;
    },
    offerClosed: (state, { payload }: PayloadAction<{ status: string }>) => {
      state.status = payload.status;
    },
    actionSucceeded: (state) => {
      state.success = true;
    },
    offerCategoryChangeSet: (state) => {
      state.categoryChange = !state.categoryChange;
    },
    languageControlSet: (state) => {
      state.languageControl = {
        ...state.languageControl,
        [state.language]: false
      };
    },
    languageControlUpdated: (state) => {
      let languageCont = {
        en: false,
        de: false,
        fr: false,
        it: false
      };

      Object.entries(state.changeable).forEach(([key, value]) => {
        if (typeof value === "object") {
          languageCont = {
            en: languageCont.en || value.en !== "",
            de: languageCont.de || value.de !== "",
            fr: languageCont.fr || value.fr !== "",
            it: languageCont.it || value.it !== ""
          };
        }
      });

      state.languageControl = {
        en: languageCont.en,
        de: languageCont.de,
        fr: languageCont.fr,
        it: languageCont.it
      };
      state.formRemoved = languageCont.en || languageCont.de || languageCont.fr || languageCont.it;
    },
    languageReset: (state) => {
      state.language = "en";
    },
    imageReset: (state, { payload }: PayloadAction<{ field: string }>) => {
      let value: { [key: string]: string };

      if (payload.field === "img1") {
        value = {
          en: state.changeable.img1.en,
          de: state.changeable.img1.de,
          fr: state.changeable.img1.fr,
          it: state.changeable.img1.it
        };
      } else {
        value = {
          en: state.changeable.img2.en,
          de: state.changeable.img2.de,
          fr: state.changeable.img2.fr,
          it: state.changeable.img2.it
        };
      }

      value[state.language] = "";

      state.changeable = {
        ...state.changeable,
        [payload.field]: value
      };
      state.languageControl = {
        ...state.languageControl,
        [state.language]: true
      };
    },
    offerCategorySet: (state, { payload }: PayloadAction<string>) => {
      state.changeable.category = payload;
    },
    offerReset: (state) => {
      const resetMultiLing: {
        [key: string]: ResetType;
      } = {};

      Object.entries(initialState).forEach(([key, value]) => {
        if (typeof value === "object" && key !== "timeInterval" && key !== "images") {
          if (typeof value.en === "string") resetMultiLing[key] = getDefaultMultilingualStringViewModel();
          else resetMultiLing[key] = resetState[key];
        }
      });

      return {
        ...initialState,
        ...resetMultiLing,
        language: state.language
      };
    },
    offerImageUpdated: (state, { payload }: PayloadAction<MultilingualStringViewModel>) => {
      state.changeable = {
        ...state.changeable,
        [state.selectedImg]: payload
      };
      state.languageControl = {
        ...state.languageControl,
        [state.language]: true
      };
    },
    offerLanguageInputsChanged: (
      state,
      { payload }: PayloadAction<{ field: string; value: MultilingualStringViewModel }>
    ) => {
      state.changeable = {
        ...state.changeable,
        [payload.field]: payload.value
      };
      state.languageControl = {
        ...state.languageControl,
        [state.language]: true
      };
    },
    offerInputsChanged: (state, { payload }: PayloadAction<OfferInputsModel>) => {
      state = { ...state, [payload.field]: payload.value };
    },
    viewUnloaded: () => initialState
  }
});

export const {
  requestFinished,
  requestStarted,
  errorHappened,
  viewUnloaded,
  offerLoaded,
  selectedOfferImageReset,
  offerCategoryChanged,
  offerImagesErrored,
  offerImagesLoaded,
  offerActivated,
  offerCategoryChangeSet,
  offerCategorySet,
  offerClosed,
  offerImageFileUploaded,
  offerImageSelected,
  offerImageUpdated,
  offerInputsChanged,
  offerLanguageChanged,
  offerLanguageInputsChanged,
  offerReset,
  offerThemeChanged,
  actionSucceeded,
  languageControlSet,
  imageReset,
  languageControlUpdated,
  filesUploadedFlagReset,
  languageReset
} = offerSlice.actions;
