import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import Customer from "../../../../domain/modules/customer/entity/Customer";
import Email from "../../../../domain/modules/Email";
import Language from "../../../../domain/modules/Language";
import Name from "../../../../domain/modules/Name";
import ZipCode from "../../../../domain/modules/ZipCode";
import { CustomerDTO } from "../../../dataSource/customerDataSource/ICustomerDataSource";
import IMapper from "../../../IMapper";

export class CustomerMapperError extends Error {
  constructor(message: string) {
    super(`[CustomerMapper] Error - ${message}`);
  }
}

export default class CustomerMapper implements IMapper<CustomerDTO, Customer> {
  map(input: CustomerDTO): Customer {
    try {
      return new Customer(
        {
          email: Email.create(input.email),
          name: Name.create(NotEmptyString.create(input.firstName), NotEmptyString.create(input.lastName)),
          language: Language.create(input.language),
          zipCode: ZipCode.create(input.zipCode),
          distributor: NotEmptyString.create(input.distributor),
          createdAt: NotEmptyString.create(input.createdAt),
          modifiedAt: input.modifiedAt ? NotEmptyString.create(input.modifiedAt) : undefined,
          obisCode: input.obisCode,
          mainMeter: input.mainMeter
        },
        StringId.create(input.id)
      );
    } catch (err: any) {
      throw new CustomerMapperError(err.message);
    }
  }
}
