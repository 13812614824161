import NotEmptyString from "../../../domain/base/valueObject/NotEmptyString";
import NumericId from "../../../domain/base/valueObject/uniqueEntityID/NumericId";
import StringId from "../../../domain/base/valueObject/uniqueEntityID/StringId";
import CreateOrderInputProcessor from "../../../domain/modules/order/presenter/CreateOrderInputProcessor";
import OrderList from "../../../domain/modules/order/valueObject/OrderList";
import OrderPresentation from "../../../gui/presenter/order/presentation/OrderPresentation";
import { CreateOrderViewModel, OrderConfigurationInputModel, OrderViewModel } from "../../../gui/presenter/order/viewModel/OrderViewModel";
import IOrderDataSource from "../../dataSource/orderDataSource/IOrderDataSource";
import IOrderMapperFactory from "./factory/IOrderMapperFactory";
import IOrderRepository from "./IOrderRepository";

export class OrderRepositoryError extends Error {
  constructor(message: string) {
    super(`[OrderRepository] Error - ${message}`);
  }
}

export default class OrderRepository implements IOrderRepository {
  private presentation: OrderPresentation;
  constructor(private _dataSource: IOrderDataSource, private _mapperFactory: IOrderMapperFactory) {
    this.presentation = new OrderPresentation();
  }

  async getOrder(orderNumber: NotEmptyString): Promise<OrderViewModel> {
    try {
      const order = this._mapperFactory.getOrderMapper().map(await this._dataSource.getOrder(orderNumber.value));
      return this.presentation.map(order);
    } catch (error: any) {
      throw new OrderRepositoryError(`[getOrder] - ${error.message}`);
    }
  }

  async getOrders(): Promise<OrderList> {
    try {
      const orders = await this._dataSource.getOrders();
      const ordersMap = orders.map((orderData) => this._mapperFactory.getOrderListItemMapper().map(orderData));
      return OrderList.create(ordersMap);
    } catch (error: any) {
      throw new OrderRepositoryError(`[getOrders] - ${error.message}`);
    }
  }

  async createOrder(order: CreateOrderViewModel): Promise<void> {
    try {
      await this._dataSource.createOrder(CreateOrderInputProcessor.map(order));
    } catch (error: any) {
      throw new OrderRepositoryError(`[createOrder] - ${error.message}`);
    }
  }

  async getOrdersForCustomer(customerId: StringId): Promise<OrderList> {
    try {
      const orders = await this._dataSource.getOrdersForCustomer(customerId.getId());
      const ordersMap = orders.map((orderData) => this._mapperFactory.getOrderListItemMapper().map(orderData));
      return OrderList.create(ordersMap);
    } catch (error: any) {
      throw new OrderRepositoryError(`[getOrdersForCustomer] - ${error.message}`);
    }
  }

  async changeStatus(id: NumericId, status: NotEmptyString): Promise<void> {
    try {
      await this._dataSource.changeStatus(id.getId().toString(), status.value.replaceAll(" ", "_"));
    } catch (error: any) {
      throw new OrderRepositoryError(`[changeStatus] - ${error.message}`);
    }
  }

  async completeOrder(id: NumericId, startDate: string, expirationDate: string): Promise<void> {
    try {
      await this._dataSource.completeOrder(id.getId().toString(), startDate, expirationDate);
    } catch (error: any) {
      throw new OrderRepositoryError(`[completeOrder] - ${error.message}`);
    }
  }

  async declineOrder(id: NumericId, declineReason: NotEmptyString): Promise<void> {
    try {
      await this._dataSource.declineOrder(id.getId().toString(), declineReason.value);
    } catch (error: any) {
      throw new OrderRepositoryError(`[declineOrder] - ${error.message}`);
    }
  }

  async updateOrderNote(id: NumericId, note: string): Promise<void> {
    try {
      await this._dataSource.updateOrderNote(id.getId().toString(), note);
    } catch (error: any) {
      throw new OrderRepositoryError(`[updateOrderNote] - ${error.message}`);
    }
  }

  async deleteOrder(id: NumericId): Promise<void> {
    try {
      await this._dataSource.deleteOrder(id.getId().toString());
    } catch (error: any) {
      throw new OrderRepositoryError(`[deleteOrder] - ${error.message}`);
    }
  }

  async deactivateOrder(orderId: NumericId, deactivationReason: NotEmptyString): Promise<void> {
    try {
      await this._dataSource.deactivateOrder(orderId.getId().toString(), deactivationReason.value);
    } catch (error: any) {
      throw new OrderRepositoryError(`[deactivateOrder] - ${error.message}`);
    }
  }

  async selectConfigurations(orderId: number, configurations: OrderConfigurationInputModel[]): Promise<void> {
    try {
      const configs = configurations.map((c) => c.value);
      await this._dataSource.selectConfigurations(orderId.toString(), configs);
    } catch (error: any) {
      throw new OrderRepositoryError(`[selectConfigurations] - ${error.message}`);
    }
  }
}
