import { ValueObject } from "../../../base/valueObject/ValueObject";

export class OrderStatusError extends Error {
  constructor(message: string) {
    super(`[OrderStatus] Error - ${message}`);
  }
}

interface OrderStatusProps {
  value: string;
}

export default class OrderStatus extends ValueObject<OrderStatusProps> {
  get value(): string {
    return this.props.value;
  }

  private constructor(props: OrderStatusProps) {
    super(props);
  }

  public static create(value: string): OrderStatus {
    if (OrderStatus.validate(value)) {
      return new OrderStatus({ value });
    } else throw new OrderStatusError("Order status cannot be undefined.");
  }

  private static validate(value: string): boolean {
    if (value === undefined || value === null) return false;
    else return true;
  }

  public equals(vo: ValueObject<OrderStatusProps>): boolean {
    return vo.props.value === this.props.value;
  }
}
