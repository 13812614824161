import { ValueObject } from "../../../base/valueObject/ValueObject";
import Customer from "../entity/Customer";

export class CustomerListError extends Error {
    constructor(message: string) {
        super(`[CustomerList] Error - ${message}`)
    }
}

interface CustomerListProps {
    customers: Customer[]
}

export default class CustomerList extends ValueObject<CustomerListProps> {
    get customers(): Customer[] {
        return this.props.customers
    }

    private constructor(props: CustomerListProps) {
        super(props)
    }

    public static create(customers: Customer[]): CustomerList {
        if (!customers)
            throw new CustomerListError('Customer list must not be undefined')        
        return new CustomerList({ customers })
    }

    public equals(vo: ValueObject<CustomerListProps>): boolean {

        if (this.props.customers.length !== vo.props.customers.length)
            return false

        const result = this.props.customers.filter(o1 => vo.props.customers.some(o2 => o1.id.isEqual(o2.id)));
       
        return result.length === this.props.customers.length
    }
}