import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import Order from "../../../../domain/modules/order/entity/Order";
import { OrderDTO, OrderHistoryDTO } from "../../../dataSource/orderDataSource/IOrderDataSource";
import IMapper from "../../../IMapper";
import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import OrderStatus from "../../../../domain/modules/order/valueObject/OrderStatus";
import NumericId from "../../../../domain/base/valueObject/uniqueEntityID/NumericId";
import OrderDetails from "../../../../domain/modules/order/valueObject/OrderDetails";
import Address from "../../../../domain/modules/Address";
import Name from "../../../../domain/modules/Name";
import MeterFeatures from "../../../../domain/modules/order/valueObject/MeterFeatures";
import MeterInfo from "../../../../domain/modules/order/valueObject/MeterInfo";
import OfferInfo from "../../../../domain/modules/order/valueObject/OfferInfo";
import OrderHistoryList from "../../../../domain/modules/order/valueObject/OrderHistoryList";
import OrderHistory from "../../../../domain/modules/order/valueObject/OrderHistory";
import Timestamp from "../../../../domain/modules/Timestamp";
import Email from "../../../../domain/modules/Email";
import ZipCode from "../../../../domain/modules/ZipCode";
import MultilingualString from "../../../../domain/modules/MultilingualString";
import NoteList from "../../../../domain/modules/order/valueObject/NoteList";
import Note from "../../../../domain/modules/order/valueObject/Note";

export class OrderMapperError extends Error {
  constructor(message: string) {
    super(`[OrderMapper] Error - ${message}`);
  }
}

export default class OrderMapper implements IMapper<OrderDTO, Order> {
  map(input: OrderDTO): Order {
    try {
      const {
        id,
        orderNumber,
        declineReason,
        deactivationReason,
        agreement,
        note,
        customer,
        info,
        name,
        offer,
        history
      } = input;
      return new Order(
        {
          customerId: customer ? StringId.create(customer) : undefined,
          orderNumber: NotEmptyString.create(orderNumber),
          notes: NoteList.create(
            note.map((n) => {
              return Note.create(
                n.autoGenerated,
                NotEmptyString.create(n.authorizedBy, "authorizedBy"),
                n.body,
                new Date(n.timestamp)
              );
            })
          ),
          status: OrderStatus.create(history[history.length - 1].status),
          declineReason: declineReason ? NotEmptyString.create(declineReason) : undefined,
          deactivationReason: deactivationReason ? NotEmptyString.create(deactivationReason) : undefined,
          offerInfo: OfferInfo.create(
            NumericId.create(offer),
            MultilingualString.create(name.en, name.de, name.fr, name.it)
          ),
          orderHistory: OrderHistoryList.create(this.convertHistoryDTOList(history)),
          details: OrderDetails.create(
            Name.create(NotEmptyString.create(info.firstName), NotEmptyString.create(info.lastName)),
            Email.create(info.email),
            Address.create(
              NotEmptyString.create(info.address),
              ZipCode.create(info.zip),
              NotEmptyString.create(info.city)
            ),
            MeterInfo.create(
              info.meterManufacturer,
              info.meterModel,
              MeterFeatures.create(info.meterHasWiFiAccess, info.meterHasPowerOutletAccess, info.hasWiFiAtHome)
            ),
            info.telephone
          ),
          agreementId: agreement ? NumericId.create(agreement) : undefined
        },
        NumericId.create(id)
      );
    } catch (err: any) {
      throw new OrderMapperError(err.message);
    }
  }

  private convertHistoryDTOList(list: OrderHistoryDTO[]): OrderHistory[] {
    return list.map((item) =>
      item.activation
        ? OrderHistory.create(
            OrderStatus.create(item.status),
            Timestamp.create(item.timestamp),
            Timestamp.create(item.activation)
          )
        : OrderHistory.create(OrderStatus.create(item.status), Timestamp.create(item.timestamp))
    );
  }
}
