import { ValueObject } from "../../../base/valueObject/ValueObject";
import MultilingualString from "../../MultilingualString";

export class OfferImageFilePathError extends Error {
    constructor(message: string) {
        super(`[OfferImageFilePath] Error - ${message}`);
    }
}

interface OfferImageFilePathProps {
    img1: MultilingualString;
    img2: MultilingualString;
}

export default class OfferImageFilePath extends ValueObject<OfferImageFilePathProps> {

    get img1(): MultilingualString {
        return this.props.img1;
    }

    get img2(): MultilingualString {
        return this.props.img2;
    }

    private constructor(props: OfferImageFilePathProps) {
        super(props);
    }

    public static create(img1: MultilingualString, img2: MultilingualString): OfferImageFilePath {
        return new OfferImageFilePath({ img1, img2 });
    }

    public equals(vo: ValueObject<OfferImageFilePathProps>): boolean {
        return vo.props.img1.equals(this.img1) && vo.props.img2.equals(this.img2);
    }

}