import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class NoteError extends Error {
  constructor(message: string) {
    super(`[Note] Error - ${message}`);
  }
}

interface NoteProps {
  autoGenerated: boolean;
  authorizedBy: NotEmptyString;
  body: string;
  timestamp: Date;
}

export default class Note extends ValueObject<NoteProps> {
  get autoGenerated(): boolean {
    return this.props.autoGenerated;
  }

  get authorizedBy(): NotEmptyString {
    return this.props.authorizedBy;
  }

  get body(): string {
    return this.props.body;
  }

  get timestamp(): Date {
    return this.props.timestamp;
  }

  public static create(
    autoGenerated: boolean,
    authorizedBy: NotEmptyString,
    body: string,
    timestamp: Date
  ): Note {
    return new Note({ autoGenerated, authorizedBy, body, timestamp });
  }

  public equals(vo: ValueObject<NoteProps>): boolean {
    if (
      vo.props.autoGenerated === this.props.autoGenerated &&
      vo.props.authorizedBy.equals(this.props.authorizedBy) &&
      vo.props.body === this.props.body &&
      vo.props.timestamp.toISOString() === this.props.timestamp.toISOString()
    )
      return true;
    else return false;
  }
}
