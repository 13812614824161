import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  defaultEventViewModel,
  EventViewModel
} from "../../../infrastructure/repository/eventRepository/IEventRepository";

interface EventState {
  initialEvent: { [key: string]: string | number | string[] };
  event: EventViewModel;
  loading: boolean;
  error?: string;
}

const initialState: EventState = {
  initialEvent: defaultEventViewModel(),
  event: defaultEventViewModel(),
  loading: false
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    eventLoaded: (state, { payload }: PayloadAction<{ event: EventViewModel }>) => {
      state.loading = false;
      state.event = payload.event;
      state.initialEvent = payload.event;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState
  }
});

export const { errorHappened, requestStarted, requestFinished, viewUnloaded, eventLoaded } = eventSlice.actions;
