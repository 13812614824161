import OfferImage from "../../../../domain/modules/offer/valueObject/OfferImage";
import IMapper from "../../../../infrastructure/IMapper";
import { OfferImageViewModel } from "../viewModel/OfferImageViewModel";

export default class OfferImagePresentation
  implements IMapper<OfferImage, OfferImageViewModel>
{
  map(input: OfferImage): OfferImageViewModel {
    return {
      url: input.url.value
    };
  }
}
