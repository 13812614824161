import { ValueObject } from "../../../base/valueObject/ValueObject";
import Timestamp from "../../Timestamp";
import OrderStatus from "./OrderStatus";

export class OrderHistoryError extends Error {
  constructor(message: string) {
    super(`[OrderHistory] Error - ${message}`);
  }
}

interface OrderHistoryProps {
  status: OrderStatus;
  timestamp: Timestamp;
  activation?: Timestamp;
}

export default class OrderHistory extends ValueObject<OrderHistoryProps> {
  get status(): OrderStatus {
    return this.props.status;
  }

  get timestamp(): Timestamp {
    return this.props.timestamp;
  }

  get activation(): Timestamp | undefined {
    return this.props.activation;
  }

  private constructor(props: OrderHistoryProps) {
    super(props);
  }

  public static create(status: OrderStatus, timestamp: Timestamp, activation?: Timestamp): OrderHistory {
    return new OrderHistory({ status, timestamp, activation });
  }

  public equals(vo: ValueObject<OrderHistoryProps>): boolean {
    if (vo.props.status.equals(this.props.status) && vo.props.timestamp.equals(this.props.timestamp))
      return true;
    else return false;
  }

  public prettify() {
    return new Date(this.timestamp.value).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  }
}
