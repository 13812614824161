import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  defaultEventTemplateViewModel,
  DistrictTagViewModel,
  EventStatisticsViewModel,
  EventTemplateInputsModel,
  EventTemplateViewModel,
} from "../../../infrastructure/repository/eventRepository/IEventRepository";

interface EventTemplateState {
  initialEventTemplate: { [key: string]: string | number | string[] };
  eventTemplate: EventTemplateViewModel;
  districtTags: DistrictTagViewModel[];
  recipients: number;
  opened: number;
  clickedRate: string;
  loading: boolean;
  loadingDistricts: boolean;
  create: boolean;
  success: boolean;
  error?: string;
}

const initialState: EventTemplateState = {
  initialEventTemplate: defaultEventTemplateViewModel(),
  eventTemplate: defaultEventTemplateViewModel(),
  districtTags: [],
  recipients: -1,
  opened: -1,
  clickedRate: "",
  success: false,
  create: false,
  loading: false,
  loadingDistricts: false,
};

export const eventTemplateSlice = createSlice({
  name: "eventTemplate",
  initialState,
  reducers: {
    eventTemplateLoaded: (state, { payload }: PayloadAction<{ eventTemplate: EventTemplateViewModel }>) => {
      state.loading = false;
      state.eventTemplate = payload.eventTemplate;
      state.initialEventTemplate = payload.eventTemplate;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
      state.loadingDistricts = false;
    },
    requestStarted: (state, { payload }: PayloadAction<{ loadingDistricts: boolean }>) => {
      payload.loadingDistricts ? (state.loadingDistricts = true) : (state.loading = true);
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    eventTemplateMagnitudeReset: (state) => {
      state.districtTags = [];
    },
    eventTemplateDistrictsSet: (state, { payload }: PayloadAction<string[]>) => {
      state.eventTemplate.districts = payload;
    },
    eventTemplateActionSucceeded: (state) => {
      state.success = true;
    },
    eventTemplateDuplicated: (state, { payload }: PayloadAction<{ duplicate: EventTemplateViewModel }>) => {
      state.eventTemplate = payload.duplicate;
      state.districtTags = [];
    },
    eventTemplateStatisticsLoaded: (state, { payload }: PayloadAction<EventStatisticsViewModel>) => {
      state.loading = false;
      state.opened = payload.totalOpened;
      state.recipients = payload.totalRecipients;
      state.clickedRate = payload.clickToRate;
      state.districtTags = payload.districts;
    },
    eventTemplateMagnitudeLoaded: (state, { payload }: PayloadAction<DistrictTagViewModel[]>) => {
      state.districtTags = payload;
      state.loadingDistricts = false;
    },
    eventTemplateStatusSet: (state, { payload }: PayloadAction<{ status: string }>) => {
      state.eventTemplate.status = payload.status;
      state.eventTemplate.frequency = payload.status === "single" ? "ONCE" : "WEEKLY";
      state.initialEventTemplate.status = payload.status;
      state.initialEventTemplate.frequency = payload.status === "single" ? "ONCE" : "WEEKLY";
    },
    eventTemplateInputsChanged: (state, { payload }: PayloadAction<EventTemplateInputsModel>) => {
      const field = payload.field;
      const value = payload.value;

      let status = state.eventTemplate.status;

      if (field === "frequency") {
        status = value === "ONCE" ? "single" : "repetitive";
      }

      return {
        ...state,
        eventTemplate: {
          ...state.eventTemplate,
          [field]: value,
          status,
        },
      };
    },
    setCreate: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        create: payload,
      };
    },
    viewUnloaded: () => initialState,
  },
});

export const {
  requestFinished,
  requestStarted,
  errorHappened,
  viewUnloaded,
  eventTemplateLoaded,
  eventTemplateActionSucceeded,
  eventTemplateDistrictsSet,
  eventTemplateDuplicated,
  eventTemplateInputsChanged,
  eventTemplateMagnitudeLoaded,
  eventTemplateMagnitudeReset,
  eventTemplateStatisticsLoaded,
  eventTemplateStatusSet,
  setCreate,
} = eventTemplateSlice.actions;
