import Order from "../../../../domain/modules/order/entity/Order";
import OrderListItem from "../../../../domain/modules/order/entity/OrderListItem";
import { OrderDTO, OrderListItemDTO } from "../../../dataSource/orderDataSource/IOrderDataSource";
import IMapper from "../../../IMapper";
import OrderDataMapper from "../mapper/OrderDataMapper";
import OrderListItemMapper from "../mapper/OrderListItemMapper";
import OrderMapper from "../mapper/OrderMapper";
import IOrderMapperFactory from "./IOrderMapperFactory";

export default class OrderMapperFactory implements IOrderMapperFactory {
    getOrderListItemMapper(): IMapper<OrderListItemDTO, OrderListItem> {
        return new OrderListItemMapper();
    }
    getOrderMapper(): IMapper<OrderDTO, Order> {
        return new OrderMapper();
    }

    getOrderDataMapper(): IMapper<Order, OrderDTO> {
        return new OrderDataMapper();
    }
}