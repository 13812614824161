import { ValueObject } from "../../../base/valueObject/ValueObject";
import OrderHistory from "./OrderHistory";

export class OrderHistoryListError extends Error {
    constructor(message: string) {
        super(`[OrderHistoryList] Error - ${message}`)
    }
}

interface OrderHistoryListProps {
    list: OrderHistory[];
}

export default class OrderHistoryList extends ValueObject<OrderHistoryListProps> {
    get list(): OrderHistory[] {
        return this.props.list;
    }

    private constructor(props: OrderHistoryListProps) {
        super(props)
    }

    public static create(list: OrderHistory[]): OrderHistoryList {
        if (!list)
            throw new OrderHistoryListError('OrderHistory list must not be undefined')
        return new OrderHistoryList({ list })
    }

    public equals(vo: ValueObject<OrderHistoryListProps>): boolean {

        if (this.props.list.length !== vo.props.list.length)
            return false

        let result = this.props.list.filter(o1 => vo.props.list.some(o2 => o1.status === o2.status && o1.timestamp === o2.timestamp));

        return result.length === this.props.list.length
    }
}