import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderListItemViewModel } from "../../presenter/order/viewModel/OrderViewModel";

interface OrderListState {
  orderList: OrderListItemViewModel[];
  orderDeletedSuccess: boolean;
  loading: boolean;
  tableSearchText: string;
  pageNumber: number;
  pageSize: number;
  error?: string;
}

const initialState: OrderListState = {
  orderList: [],
  orderDeletedSuccess: false,
  tableSearchText: "",
  pageNumber: 0,
  pageSize: 20,
  loading: false,
};

export const orderListSlice = createSlice({
  name: "orderList",
  initialState,
  reducers: {
    ordersLoaded: (state, { payload }: PayloadAction<{ orders: OrderListItemViewModel[] }>) => {
      state.loading = false;
      state.orderList = payload.orders.sort(
        (o1, o2) =>
          new Date(o2.orderHistory[o2.orderHistory.length - 1].timestamp).getTime() - new Date(o1.orderHistory[o1.orderHistory.length - 1].timestamp).getTime()
      );
    },
    searchBarSet: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.tableSearchText = payload.value;
    },
    pageNumberSet: (state, { payload }: PayloadAction<{ value: number }>) => {
      state.pageNumber = payload.value;
    },
    pageSizeSet: (state, { payload }: PayloadAction<{ value: number }>) => {
      state.pageSize = payload.value;
    },
    orderDeleted: (state, { payload }: PayloadAction<{ orderId: number }>) => {
      const filteredList = state.orderList.filter((order) => order.id !== payload.orderId);
      state.loading = false;
      state.orderDeletedSuccess = true;
      state.orderList = filteredList;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    errorReset: (state) => {
      state.error = undefined;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState,
  },
});

export const {
  ordersLoaded,
  errorHappened,
  requestStarted,
  requestFinished,
  viewUnloaded,
  searchBarSet,
  pageNumberSet,
  orderDeleted,
  pageSizeSet,
  errorReset,
} = orderListSlice.actions;
