import { MultilingualStringViewModel } from "../../presenter/offer/viewModel/MultilingualStringViewModel";

export const getStatus = (rowData: string) => {
  switch (rowData) {
    case "PENDING":
      return "Offer requested";

    case "CONTRACT_SENT":
      return "In Progress";

    case "HW_SENT":
      return "Hardware sent";

    case "CHECKING_DATA_QUALITY":
      return "Data quality review";

    case "WAITING_ON_ACTIVATION":
      return "Waiting on activation";

    case "COMPLETED":
      return "In Use";

    case "DEACTIVATED":
      return "Deactivated";

    case "DECLINED":
      return "Declined";

    default:
      return "";
  }
};

export const getFormattedDate = (date: string) => {
  const d = new Date(date).toLocaleDateString("en-UK", {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  });
  return d.replaceAll("/", ".");
};

export const getFormattedDateTime = (date: string) => {
  const d = new Date(date).toLocaleDateString("en-UK", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
  return d.replaceAll("/", ".").replace(",", " |");
};

export const getFormattedTime = (date: string) => {
  const d = new Date(date).toLocaleDateString("en-UK", {
    hour: "2-digit",
    minute: "2-digit"
  });
  return d.substring(d.indexOf(",") + 2);
};

export const sortOfferTitle = (data1: any, data2: any, type: any) => {
  if (data1.offerInfo.title.en.toLocaleLowerCase() < data2.offerInfo.title.en.toLocaleLowerCase()) {
    return -1;
  }
  if (data1.offerInfo.title.en.toLocaleLowerCase() > data2.offerInfo.title.en.toLocaleLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortTimestamp = (data1: any, data2: any, type: any) => {
  const timestamp1 = data1.orderHistory[data1.orderHistory.length - 1].timestamp;
  const timestamp2 = data2.orderHistory[data2.orderHistory.length - 1].timestamp;

  if (timestamp1 < timestamp2) {
    return -1;
  }
  if (timestamp1 > timestamp2) {
    return 1;
  }
  return 0;
};

export const sortValues = (first: string, second: string, type: string) => {
  if (first < second) {
    return type === "asc" ? -1 : 1;
  } else if (first > second) {
    return type === "asc" ? 1 : -1;
  } else return 0;
};

export const sortStatus = (status1: string, status2: string, type: string) => {
  const statusOrder = [
    "PENDING",
    "CONTRACT_SENT",
    "HW_SENT",
    "CHECKING_DATA_QUALITY",
    "WAITING_ON_ACTIVATION",
    "COMPLETED",
    "DEACTIVATED",
    "DECLINED"
  ];

  if (statusOrder.indexOf(status1) < statusOrder.indexOf(status2)) {
    return type === "asc" ? -1 : 1;
  } else if (statusOrder.indexOf(status1) > statusOrder.indexOf(status2)) {
    return type === "asc" ? 1 : -1;
  } else return 0;
};

export const searchFilter = (value: string, search: string) => value.toLocaleLowerCase().includes(search);

export const getTitle = (orderTitle: MultilingualStringViewModel) =>
  orderTitle.en !== ""
    ? orderTitle.en
    : orderTitle.de !== ""
    ? orderTitle.de
    : orderTitle.fr !== ""
    ? orderTitle.fr
    : orderTitle.it !== ""
    ? orderTitle.it
    : orderTitle.en;
