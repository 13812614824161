import MultilingualString from "../../../../domain/modules/MultilingualString";
import OfferImageFilePath from "../../../../domain/modules/offer/valueObject/OfferImageFilePath";
import IMapper from "../../../../infrastructure/IMapper";
import { MultilingualStringViewModel } from "../viewModel/MultilingualStringViewModel";
import { OfferImageFilePathViewModel } from "../viewModel/OfferImageFilePathViewModel";

export default class OfferImageFilePresentation
  implements IMapper<OfferImageFilePath, OfferImageFilePathViewModel>
{
  public present(newPaths: OfferImageFilePath, oldPaths: OfferImageFilePath): OfferImageFilePathViewModel {
    const newPathsViewModel = this.map(newPaths);
    const oldPathsViewModel = this.map(oldPaths);

    return {
      img1: {
        en: this.setField(newPathsViewModel.img1.en, oldPathsViewModel.img1.en),
        de: this.setField(newPathsViewModel.img1.de, oldPathsViewModel.img1.de),
        fr: this.setField(newPathsViewModel.img1.fr, oldPathsViewModel.img1.fr),
        it: this.setField(newPathsViewModel.img1.it, oldPathsViewModel.img1.it)
      },
      img2: {
        en: this.setField(newPathsViewModel.img2.en, oldPathsViewModel.img2.en),
        de: this.setField(newPathsViewModel.img2.de, oldPathsViewModel.img2.de),
        fr: this.setField(newPathsViewModel.img2.fr, oldPathsViewModel.img2.fr),
        it: this.setField(newPathsViewModel.img2.it, oldPathsViewModel.img2.it)
      }
    };
  }

  map(input: OfferImageFilePath): OfferImageFilePathViewModel {
    return {
      img1: this.extractMultilingualValue(input.img1),
      img2: this.extractMultilingualValue(input.img2)
    };
  }

  private extractMultilingualValue(data: MultilingualString): MultilingualStringViewModel {
    return {
      en: data.en,
      de: data.de,
      fr: data.fr,
      it: data.it
    };
  }

  private setField(newPathData: string, oldPathData: string) {
    return newPathData !== "" ? newPathData : oldPathData;
  }
}
