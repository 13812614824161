import { configureStore } from "@reduxjs/toolkit";
import { baseSlice } from "./base/BaseSlice";
import { customerSlice } from "./customer/CustomerSlice";
import { customerListSlice } from "./customerList/CustomerListSlice";
import { eventSlice } from "./event/EventSlice";
import { eventListSlice } from "./eventList/EventListSlice";
import { eventTemplateSlice } from "./eventTemplate/EventTemplateSlice";
import { eventTemplateListSlice } from "./eventTemplateList/EventTemplateListSlice";
import { forgotPasswordSlice } from "./forgotPassword/ForgotPasswordSlice";
import { myProfileSlice } from "./myProfile/MyProfileSlice";
import { offerSlice } from "./offer/OfferSlice";
import { offerListSlice } from "./offerList/OfferListSlice";
import { orderSlice } from "./order/OrderSlice";
import { orderListSlice } from "./orderList/OrderListSlice";
import { settingsSlice } from "./settings/SettingsSlice";
import { signInSlice } from "./signIn/SignInSlice";
import { signOutSlice } from "./signOut/SignOutSlice";
import { newOrderSlice } from "./order/NewOrderSlice";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
  reducer: {
    signIn: signInSlice.reducer,
    base: baseSlice.reducer,
    orderList: orderListSlice.reducer,
    order: orderSlice.reducer,
    myProfile: myProfileSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    customerList: customerListSlice.reducer,
    customer: customerSlice.reducer,
    event: eventSlice.reducer,
    eventList: eventListSlice.reducer,
    eventTemplateList: eventTemplateListSlice.reducer,
    offerList: offerListSlice.reducer,
    settings: settingsSlice.reducer,
    eventTemplate: eventTemplateSlice.reducer,
    offer: offerSlice.reducer,
    signOut: signOutSlice.reducer,
    newOrder: newOrderSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
