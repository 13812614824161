import Offer from "../../../../domain/modules/offer/entity/Offer";
import MultilingualString from "../../../../domain/modules/MultilingualString";
import { OfferEditData } from "../../../dataSource/offerDataSource/IOfferDataSource";
import IMapper from "../../../IMapper";
import { CreateOfferInputs } from "../../../../domain/modules/offer/useCase/processor/CreateOfferInputProcessor";

export default class OfferEditDataMapper implements IMapper<Offer | CreateOfferInputs, OfferEditData> {
  map(input: Offer | CreateOfferInputs): OfferEditData {
    const { category, name, header, img1, img2, description, details } = input;

    return {
      utility: "energieuster",
      category: category.value,
      name: this.extractMultilingualValue(name),
      header: this.extractMultilingualValue(header),
      img1: this.extractMultilingualValue(img1),
      img2: this.extractMultilingualValue(img2),
      description: this.extractMultilingualValue(description),
      details: this.extractMultilingualValue(details)
    };
  }

  private extractMultilingualValue(data: MultilingualString) {
    return {
      en: data.en,
      de: data.de,
      fr: data.fr,
      it: data.it
    };
  }
}
