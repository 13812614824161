import IMessageRepository, { MessageCreateDTO, MessageViewModel } from "./IMessageRepository";
import moment from "moment";
import IMessageDataSource from "../../dataSource/messageDataSource/IMessageDataSource";

export default class MessageRepository implements IMessageRepository {
  constructor(private _dataSource: IMessageDataSource) {}

  public async getMessages(): Promise<MessageViewModel[]> {
    try {
      const messages = await this._dataSource.getMessages();
      const result = messages.map((message) => {
        return {
          ...message,
          scheduledDate: this.getDateObject(message.scheduledDate)
        };
      });

      return result;
    } catch (e: any) {
      throw new Error(`[getMessages] - ${e.message}`);
    }
  }

  public async getMessage(messageId: string): Promise<MessageViewModel> {
    try {
      const message = await this._dataSource.getMessage(messageId);
      return {
        ...message,
        scheduledDate: this.getDateObject(message.scheduledDate)
      };
    } catch (e: any) {
      throw new Error(`[getMessage] - ${e.message}`);
    }
  }

  public async createMessage(message: MessageCreateDTO, isScheduled: boolean): Promise<void> {
    try {
      if (isScheduled && message.scheduledDate) {
        const date = new Date(message.scheduledDate);
        const formattedDate = {
          date: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          hours: date.getHours()
        };

        return await this._dataSource.createMessage({
          ...message,
          scheduledDate: `${formattedDate.date}/${formattedDate.month}/${formattedDate.year}`
        });
      } else {
        const { scheduledDate, scheduledHour, ...instantMessage } = message;
        return await this._dataSource.createMessage(instantMessage);
      }
    } catch (e: any) {
      throw new Error(`[createMessage] - ${e.message}`);
    }
  }

  private getDateObject(date: string) {
    if (date) {
      const dateMomentObject = moment(date, "DD/MM/YYYY");
      const dateObject = dateMomentObject.toDate();
      return dateObject.toString();
    } else return "";
  }
}
