import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EventTemplateViewModel,
  EventViewModel
} from "../../../infrastructure/repository/eventRepository/IEventRepository";

interface EventTemplateListState {
  eventTemplateList: EventTemplateViewModel[];
  templateLiveEvents: EventViewModel[];
  loading: boolean;
  error?: string;
}

const initialState: EventTemplateListState = {
  eventTemplateList: [],
  templateLiveEvents: [],
  loading: false
};

export const eventTemplateListSlice = createSlice({
  name: "eventTemplateList",
  initialState,
  reducers: {
    eventTemplatesLoaded: (state, { payload }: PayloadAction<{ eventTemplates: EventTemplateViewModel[] }>) => {
      const data = payload.eventTemplates
        .filter((template) => (template.status === "single" ? new Date() < new Date(template.eventEndDate) : template))
        .sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          else if (a.createdAt < b.createdAt) return 1;
          else return 0;
        });

      state.loading = false;
      state.eventTemplateList = data;
    },
    liveEventsForTemplateLoaded: (state, { payload }: PayloadAction<{ events: EventViewModel[] }>) => {
      state.templateLiveEvents = payload.events;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState
  }
});

export const {
  requestFinished,
  requestStarted,
  errorHappened,
  viewUnloaded,
  eventTemplatesLoaded,
  liveEventsForTemplateLoaded
} = eventTemplateListSlice.actions;
