import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OfferViewModel } from "../../presenter/offer/viewModel/OfferViewModel";

interface OfferListState {
  offerList: OfferViewModel[];
  loading: boolean;
  error?: string;
}

const initialState: OfferListState = {
  offerList: [],
  loading: false
};

export const offerListSlice = createSlice({
  name: "offerList",
  initialState,
  reducers: {
    offersLoaded: (state, { payload }: PayloadAction<{ offers: OfferViewModel[] }>) => {
      state.offerList = payload.offers;
      state.loading = false;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState
  }
});

export const { errorHappened, requestStarted, requestFinished, viewUnloaded, offersLoaded } = offerListSlice.actions;
