import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomerViewModel } from "../../presenter/customer/viewModel/CustomerViewModel";
import { OrderViewModel } from "../../presenter/order/viewModel/OrderViewModel";

interface CustomerEnergyInfoState {
  user?: string;
  buildingType?: string;
  roomsQuantity?: number;
  peopleQuantity?: number;
  tumbleDrier?: boolean;
  waterHeater?: boolean;
  heater?: boolean;
  waterHeaterType?: string;
  waterHeaterSolarThermalEnergy?: boolean;
  waterHeaterPhotovoltaic?: boolean;
  heaterType?: string;
  heaterSolarThermalEnergy?: boolean;
  heaterPhotovoltaic?: boolean;
  tumbleDrierUseCommon?: boolean;
  waterHeaterCentrallyManaged?: boolean;
  heaterCentrallyManaged?: boolean;
  createdAt?: string;
  modifiedAt?: string;
}

interface CustomerState {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  language: string;
  zipCode: string;
  distributor: string;
  createdAt: string;
  modifiedAt?: string;
  obisCode: string;
  mainMeter: string;
  orders: OrderViewModel[];
  loading: boolean;
  error?: string;
  energyInfo: CustomerEnergyInfoState;
  changeable: { obisCode: string };
  activeStep: number;
}

const initialState: CustomerState = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  language: "",
  zipCode: "",
  distributor: "",
  createdAt: "",
  obisCode: "",
  mainMeter: "",
  orders: [],
  loading: false,
  energyInfo: {},
  activeStep: 0,
  changeable: { obisCode: "" },
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    customerLoaded: (
      state,
      { payload }: PayloadAction<{ customer: CustomerViewModel }>
    ) => {
      const { customer } = payload;
      const newState = Object.entries(customer).reduce((acc, curr) => {
        let k = curr[0];
        let v = curr[1];
        return { ...acc, [k]: v };
      }, {});

      return {
        ...state,

        changeable: { obisCode: customer.obisCode },
        ...newState,
      };
    },
    customerLoadedWithEnergyInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        customer: CustomerViewModel;
        energyInfo: CustomerEnergyInfoState;
      }>
    ) => {
      const { customer, energyInfo } = payload;
      const newState = Object.entries(customer).reduce((acc, curr) => {
        let k = curr[0];
        let v = curr[1];
        return { ...acc, [k]: v };
      }, {});

      return {
        ...state,
        ...newState,
        loading: false,
        energyInfo,
      };
    },
    activeStepChanged: (state, { payload }: PayloadAction<number>) => {
      state.activeStep = payload;
    },
    energyInfoLoaded: (
      state,
      { payload }: PayloadAction<{ energyInfo: CustomerEnergyInfoState }>
    ) => {
      state.loading = false;
      state.energyInfo = payload.energyInfo;
    },
    obisCodeInputChanged: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.changeable.obisCode = payload;
    },
    obisCodeUpdated: (state, { payload }: PayloadAction<string>) => {
      state.loading = false;
      state.activeStep = 1;
      state.obisCode = payload;
      state.changeable = {
        obisCode: payload,
      };
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState,
  },
});

export const {
  customerLoaded,
  errorHappened,
  requestStarted,
  requestFinished,
  viewUnloaded,
  energyInfoLoaded,
  activeStepChanged,
  obisCodeInputChanged,
  obisCodeUpdated,
  customerLoadedWithEnergyInfo,
} = customerSlice.actions;
