import getDefaultMultilingualFileViewModel, { MultilingualFileViewModel } from "./MultilingualFileViewModel";

export type OfferImageFileViewModel = { [key: string]: MultilingualFileViewModel };

const getDefaultOfferImageFileViewModel = (): OfferImageFileViewModel => {
  return {
    img1: getDefaultMultilingualFileViewModel(),
    img2: getDefaultMultilingualFileViewModel()
  };
};

export default getDefaultOfferImageFileViewModel;
