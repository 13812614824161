import { ValueObject } from "../../../base/valueObject/ValueObject";

export class MeterFeaturesError extends Error {
  constructor(message: string) {
    super(`[MeterFeatures] Error - ${message}`);
  }
}

interface MeterFeaturesProps {
  wifiAccess: boolean;
  powerOutletAccess: boolean;
  wifiAtHome: boolean;
}

export default class MeterFeatures extends ValueObject<MeterFeaturesProps> {
  get wifiAccess(): boolean {
    return this.props.wifiAccess;
  }

  get powerOutletAccess(): boolean {
    return this.props.powerOutletAccess;
  }

  get wifiAtHome(): boolean {
    return this.props.wifiAtHome;
  }

  private constructor(props: MeterFeaturesProps) {
    super(props);
  }

  public static create(wifiAccess: boolean, powerOutletAccess: boolean, wifiAtHome: boolean): MeterFeatures {
    if (!this.validate(wifiAccess)) throw new MeterFeaturesError("WifiAccess cannot be undefined.");
    else if (!this.validate(powerOutletAccess))
      throw new MeterFeaturesError("PowerOutletAccess cannot be undefined.");
    else if (!this.validate(wifiAtHome)) throw new MeterFeaturesError("WiFiAtHome cannot be undefined.");

    return new MeterFeatures({ wifiAccess, powerOutletAccess, wifiAtHome });
  }

  public static validate(value: boolean): boolean {
    if (value !== undefined && value !== null) return true;
    else return false;
  }

  public equals(vo: ValueObject<MeterFeaturesProps>): boolean {
    if (
      vo.props.wifiAccess === this.props.wifiAccess &&
      vo.props.wifiAtHome === this.props.wifiAtHome &&
      vo.props.powerOutletAccess === this.props.powerOutletAccess
    )
      return true;
    else return false;
  }
}
