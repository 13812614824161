import { ValueObject } from "../../../base/valueObject/ValueObject";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import MultilingualString from "../../MultilingualString";

export class OfferInfoError extends Error {
    constructor(message: string) {
        super(`[OfferInfo] Error - ${message}`)
    }
}

interface OfferInfoProps {
    id: NumericId;
    title: MultilingualString;
}

export default class OfferInfo extends ValueObject<OfferInfoProps> {

    get id(): NumericId {
        return this.props.id;
    }

    get title(): MultilingualString {
        return this.props.title;
    }

    private constructor(props: OfferInfoProps) {
        super(props);
    }

    public static create(id: NumericId, title: MultilingualString): OfferInfo {
        return new OfferInfo({ id, title });
    }

    public equals(vo: ValueObject<OfferInfoProps>): boolean {
        if (vo.props.id.equals(this.props.id)
            && vo.props.title.equals(this.props.title))
            return true;
        else return false;
    }
}