import MultilingualString from "../../../../domain/modules/MultilingualString";
import OfferImageFile from "../../../../domain/modules/offer/valueObject/OfferImageFile";
import OfferImageFilePath from "../../../../domain/modules/offer/valueObject/OfferImageFilePath";
import {
  MultilingualStringDTO,
  FilePathDTO
} from "../../../dataSource/offerDataSource/IOfferDataSource";
import IMapper from "../../../IMapper";

export type OfferImageFileMap = {
  newPaths: FilePathDTO[];
  images: OfferImageFile;
};

export default class OfferImageFilePathMapper
  implements IMapper<OfferImageFileMap, OfferImageFilePath>
{
  map(input: OfferImageFileMap): OfferImageFilePath {
    const { newPaths, images } = input;

    const inputTemorary = {
      img1: {
        en: images.img1.en !== undefined ? this.mapArray(images.img1.en, newPaths) : "",
        de: images.img1.de !== undefined ? this.mapArray(images.img1.de, newPaths) : "",
        fr: images.img1.fr !== undefined ? this.mapArray(images.img1.fr, newPaths) : "",
        it: images.img1.it !== undefined ? this.mapArray(images.img1.it, newPaths) : ""
      },
      img2: {
        en: images.img2.en !== undefined ? this.mapArray(images.img2.en, newPaths) : "",
        de: images.img2.de !== undefined ? this.mapArray(images.img2.de, newPaths) : "",
        fr: images.img2.fr !== undefined ? this.mapArray(images.img2.fr, newPaths) : "",
        it: images.img2.it !== undefined ? this.mapArray(images.img2.it, newPaths) : ""
      }
    };

    return OfferImageFilePath.create(
      this.createMultilingualData(inputTemorary.img1),
      this.createMultilingualData(inputTemorary.img2)
    );
  }

  private mapArray(original: File, newPaths: FilePathDTO[]) {
    const mapped = newPaths.filter((path) => original.name === path.name);
    return mapped[0].path;
  }

  private createMultilingualData(input: MultilingualStringDTO): MultilingualString {
    return MultilingualString.create(input.en, input.de, input.fr, input.it);
  }
}
