import NetworkController from "../../network/NetworkController";
import ISettingsDataSource, { NotificationSubscriberDTO, SettingsDTO } from "./ISettingsDataSource";

export class SettingsDataSource implements ISettingsDataSource {
  constructor(private nwc: NetworkController) {}

  async getSettings(): Promise<SettingsDTO> {
    try {
      const settings: any = await this.nwc.request({
        url: "/utility-app/settings",
        method: "GET",
        useToken: true
      });
      return settings.data;
    } catch (err: any) {
      throw new Error(`SettingsDS - [getSettings] - ${err.message}`);
    }
  }

  async deleteZipCode(zipCodes: string[]): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/settings/zip-codes",
        method: "POST",
        useToken: true,
        data: { zipCodes }
      });
    } catch (err: any) {
      throw new Error(`SettingsDS - [deleteZipCode] - ${err.message}`);
    }
  }

  async deleteSubscriber(email: string): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/settings/delete-email-subscriber",
        method: "DELETE",
        useToken: true,
        data: { email }
      });
    } catch (err: any) {
      throw new Error(`SettingsDS - [deleteSubscriber] - ${err.message}`);
    }
  }

  async addZipCode(zipCodes: string[]): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/settings/zip-codes",
        method: "POST",
        useToken: true,
        data: { zipCodes }
      });
    } catch (err: any) {
      throw new Error(`SettingsDS - [addZipCode] - ${err.message}`);
    }
  }

  async addSubscriber(subscribers: NotificationSubscriberDTO[]): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/settings/add-email-subscribers",
        method: "POST",
        useToken: true,
        data: { subscribers }
      });
    } catch (err: any) {
      throw new Error(`SettingsDS - [addSubscriber] - ${err.message}`);
    }
  }

  async getDistricts(): Promise<string[]> {
    try {
      const districts: any = await this.nwc.request({
        url: "/utility-app/settings/districts",
        method: "GET",
        useToken: true
      });
      return districts.data;
    } catch (err: any) {
      throw new Error(`SettingsDS - [getDistricts] - ${err.message}`);
    }
  }

  async addDistrict(district: string): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/settings/add-district",
        method: "POST",
        useToken: true,
        data: { district }
      });
    } catch (err: any) {
      throw new Error(`SettingsDS - [addDistrict] - ${err.message}`);
    }
  }

  async deleteDistrict(district: string): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/settings/delete-district",
        method: "DELETE",
        useToken: true,
        data: { district }
      });
    } catch (err: any) {
      throw new Error(`SettingsDS - [deleteDistrict] - ${err.message}`);
    }
  }

  async editDistrict(oldDistrict: string, newDistrict: string): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/settings/rename-district",
        method: "POST",
        useToken: true,
        data: { oldDistrict, newDistrict }
      });
    } catch (err: any) {
      throw new Error(`SettingsDS - [editDistrict] - ${err.message}`);
    }
  }
}
