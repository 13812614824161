import IEventDataSource, {
  EventTemplateDTO,
  EventTemplateEditData,
  EventDTO,
  EventTagDTO,
  EventStatisticsDTO,
} from "../../dataSource/eventDataSource/IEventDataSource";
import IEventRepository, { DistrictTagViewModel, EventStatisticsViewModel, EventTemplateViewModel, EventViewModel } from "./IEventRepository";

export class EventRepositoryError extends Error {
  constructor(message: string) {
    super(`[EventRepository] Error - ${message}`);
  }
}

export default class EventRepository implements IEventRepository {
  constructor(private _dataSource: IEventDataSource) {}

  async createEventTemplate(eventTemplate: EventTemplateEditData): Promise<void> {
    try {
      const districts = [];
      districts.push("ALL");
      if (eventTemplate.districts) districts.push(...eventTemplate.districts);
      eventTemplate.districts = districts;

      await this._dataSource.createEventTemplate(eventTemplate);
    } catch (err: any) {
      throw new EventRepositoryError(`[createEventTemplate] - ${err.message}`);
    }
  }

  async getEventTemplate(id: string): Promise<EventTemplateViewModel> {
    try {
      const eventTemplate = await this._dataSource.getEventTemplate(id);
      return this.mapEventTemplate(eventTemplate);
    } catch (err: any) {
      throw new EventRepositoryError(`[getEvent] - ${err.message}`);
    }
  }

  async getEventTemplates(): Promise<EventTemplateViewModel[]> {
    try {
      const events = await this._dataSource.getEventTemplates();
      return events.map((e) => this.mapEventTemplate(e));
    } catch (err: any) {
      throw new EventRepositoryError(`[getEvent] - ${err.message}`);
    }
  }

  async updateEventTemplate(id: string, eventTemplate: EventTemplateEditData): Promise<void> {
    try {
      const districts = [];
      districts.push("ALL");
      if (eventTemplate.districts) districts.push(...eventTemplate.districts);
      eventTemplate.districts = districts;
      await this._dataSource.updateEventTemplate(id, eventTemplate);
    } catch (err: any) {
      throw new EventRepositoryError(`[updateEventTemplate] - ${err.message}`);
    }
  }

  async deleteEventTemplate(id: string): Promise<void> {
    try {
      await this._dataSource.deleteEventTemplate(id);
    } catch (err: any) {
      throw new Error(`EventRepository - [deleteEventTemplate] Error`, { cause: err.cause });
    }
  }

  async getEvent(id: string): Promise<EventViewModel> {
    try {
      const eventTemplate = await this._dataSource.getEvent(id);
      return this.mapEvent(eventTemplate);
    } catch (err: any) {
      throw new EventRepositoryError(`[getEvent] - ${err.message}`);
    }
  }

  async getEventMagnitude(districts: string[], type: string): Promise<DistrictTagViewModel[]> {
    try {
      const tagsDTO = await this._dataSource.getEventMagnitude(districts, type);
      const filteredTags = this.filterTags(tagsDTO.tags);
      districts.forEach((district) => {
        if (district !== "ALL" && !filteredTags.some((tag) => tag.value === district)) {
          filteredTags.push({
            value: district,
            count: 0,
          });
        }
      });

      return filteredTags;
    } catch (err: any) {
      throw new EventRepositoryError(`[getEventMagnitude] - ${err.message}`);
    }
  }

  async getHistoryEvents(from: string, to: string): Promise<EventViewModel[]> {
    try {
      const events = await this._dataSource.getHistoryEvents(from, to);
      return events.map((e) => this.mapEvent(e));
    } catch (err: any) {
      throw new EventRepositoryError(`[getHistoryEvent] - ${err.message}`);
    }
  }

  async getEventStatistics(id: string): Promise<EventStatisticsViewModel> {
    try {
      const statistics = await this._dataSource.getEventStatistics(id);
      return this.mapEventStatisticsViewModel(statistics);
    } catch (err: any) {
      throw new EventRepositoryError(`[getHistoryEvent] - ${err.message}`);
    }
  }

  async getLiveEventsForTemplate(templateId: string): Promise<EventViewModel[]> {
    try {
      const events = await this._dataSource.getLiveEventsForTemplate(templateId);
      return events.map((e) => this.mapEvent(e));
    } catch (err: any) {
      throw new EventRepositoryError(`[getLiveEventsForTemplate] - ${err.message}`);
    }
  }

  private mapEventStatisticsViewModel(statistics: EventStatisticsDTO): EventStatisticsViewModel {
    try {
      return {
        totalRecipients: statistics.totalCount,
        totalOpened: statistics.readCount,
        clickToRate: `${Math.ceil((statistics.readCount * 100) / statistics.totalCount)}%`,
        districts: statistics.tags ? this.filterTags(statistics.tags) : [],
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }

  private calculateNotificationStartDate(eventStart: string, timeBeforeStart: number): string {
    const eventTimeMillis = new Date(eventStart).getTime();
    const timeBeforeEventMillis = eventTimeMillis - timeBeforeStart;

    return new Date(timeBeforeEventMillis).toISOString();
  }

  private mapEventTemplate(event: EventTemplateDTO): EventTemplateViewModel {
    try {
      return {
        id: event.id,
        utility: event.utility,
        frequency: event.frequency,
        type: event.type,
        eventStartDate: event.eventStartDate,
        eventEndDate: event.eventEndDate,
        relevantNotificationDate: this.calculateNotificationStartDate(event.eventStartDate, parseInt(event.relevantTimeBeforeStart)),
        title: event.title,
        shortDescription: event.shortDescription,
        detailedDescription: event.detailedDescription,
        districts: event.districts.filter((district) => district !== "ALL"),
        createdAt: event.createdAt,
        status: event.frequency.toLocaleLowerCase() === "once" ? "single" : "repetitive",
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }

  private mapEvent(event: EventDTO): EventViewModel {
    try {
      return {
        id: event.id,
        state: event.state,
        template: event.template,
        frequency: event.frequency,
        type: event.type,
        utility: event.utility,
        eventStartDate: event.startDate,
        eventEndDate: event.endDate,
        districts: event.districts,
        title: event.title,
        shortDescription: event.shortDescription,
        detailedDescription: event.detailedDescription,
        createdAt: event.createdAt,
      };
    } catch (e: any) {
      throw new Error(e);
    }
  }

  private filterTags = (tags: EventTagDTO[]): DistrictTagViewModel[] => {
    const filteredTags = tags.filter((tagEntry) => tagEntry.tag === "district");
    return filteredTags.map((tag) => this.mapDistrictViewModel(tag));
  };

  private mapDistrictViewModel = (tag: EventTagDTO): DistrictTagViewModel => {
    return {
      value: tag.value === "ALL" ? "Recipients subscribed to all districts" : (tag.value as string),
      count: tag.count,
    };
  };
}
