import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CustomerViewModel } from "../../presenter/customer/viewModel/CustomerViewModel";
import getDefaultOfferViewModel, { OfferViewModel } from "../../presenter/offer/viewModel/OfferViewModel";

export type CustomerInfoKeys = "firstName" | "lastName" | "email" | "address" | "zip" | "city" | "telephone";

export const initialCustomerData: CustomerViewModel = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  language: "",
  zipCode: "",
  distributor: "",
  createdAt: "",
  obisCode: "",
  mainMeter: "",
  orders: [],
  modifiedAt: "",
};

interface NewOrderState {
  customer: CustomerViewModel;
  offer: OfferViewModel;
  // offerId: string;
  // offerName: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  zip: string;
  city: string;
  telephone?: string;
  meterLookType: string;
  meterTypeModel: string;
  wifiAccess: string;
  powerOutletNearElectricMeter: string;
  loading: boolean;
  error?: string;
}

const initialState: NewOrderState = {
  customer: initialCustomerData,
  firstName: "",
  lastName: "",
  email: "",
  address: "",
  zip: "",
  city: "",
  offer: getDefaultOfferViewModel(),
  meterLookType: "",
  meterTypeModel: "",
  wifiAccess: "",
  powerOutletNearElectricMeter: "",
  loading: false,
};

export const newOrderSlice = createSlice({
  name: "newOrder",
  initialState,
  reducers: {
    errorHappened: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    customerSet: (state, { payload }: PayloadAction<{ customer: CustomerViewModel }>) => {
      state.customer = payload.customer;
      state.firstName = payload.customer.firstName;
      state.lastName = payload.customer.lastName;
      state.email = payload.customer.email;
      state.zip = payload.customer.zipCode;
    },
    offerSet: (state, { payload }: PayloadAction<{ offer: OfferViewModel }>) => {
      state.offer = payload.offer;
    },
    inputChanged: (state, { payload }: PayloadAction<{ field: CustomerInfoKeys; value: string }>) => {
      state[payload.field] = payload.value;
    },
    meterLookTypeSet: (state, { payload }: PayloadAction<string>) => {
      state.meterLookType = payload;
    },
    meterTypeModelInputChanged: (state, { payload }: PayloadAction<string>) => {
      state.meterTypeModel = payload;
    },
    wifiAccessSet: (state, { payload }: PayloadAction<string>) => {
      state.wifiAccess = payload;
    },
    powerOutletNearElectricMeterSet: (state, { payload }: PayloadAction<string>) => {
      state.powerOutletNearElectricMeter = payload;
    },
    viewUnloaded: () => initialState,
  },
});

export const {
  errorHappened,
  requestFinished,
  requestStarted,
  customerSet,
  offerSet,
  inputChanged,
  meterLookTypeSet,
  meterTypeModelInputChanged,
  wifiAccessSet,
  powerOutletNearElectricMeterSet,
  viewUnloaded,
} = newOrderSlice.actions;
