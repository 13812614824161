import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import { ValueObject } from "../../../base/valueObject/ValueObject";

interface TokenProps {
  accessToken: NotEmptyString;
  refreshToken: NotEmptyString;
}

export default class Token extends ValueObject<TokenProps> {
  get accessToken() {
    return this.props.accessToken;
  }

  get refreshToken() {
    return this.props.refreshToken;
  }

  public equals(vo: ValueObject<TokenProps>): boolean {
    return this.props.accessToken === vo.props.accessToken && this.props.refreshToken === vo.props.refreshToken;
  }
}
