import { ValueObject } from "../../../base/valueObject/ValueObject";

interface OfferStatusProps {
    value: string;
}

export default class OfferStatus extends ValueObject<OfferStatusProps> {
    private static DEFAULT_OFFER_STATUS = "ACTIVE";

    get value(): string {
        return this.props.value;
    }

    private constructor(props: OfferStatusProps) {
        super(props);
    }

    public static create(value: string): OfferStatus {
        return new OfferStatus({ value: OfferStatus.validate(value) ? value : OfferStatus.DEFAULT_OFFER_STATUS });
    }

    private static validate(value?: string): boolean {
        if (value === undefined || value === null)
            return false;
        else return true;
    }

    public equals(vo: ValueObject<OfferStatusProps>): boolean {
        return vo.props.value === this.props.value;
    }
}