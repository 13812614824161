import Entity from "../../../base/entity/Entity";
import NumericId from "../../../base/valueObject/uniqueEntityID/NumericId";
import MultilingualString from "../../MultilingualString";
import OfferCategory from "../valueObject/OfferCategory";
import OfferStatus from "../valueObject/OfferStatus";

interface OfferProps {
  category: OfferCategory;
  status: OfferStatus;
  name: MultilingualString;
  header: MultilingualString;
  img1: MultilingualString;
  img2: MultilingualString;
  description: MultilingualString;
  details: MultilingualString;
}

export default class Offer extends Entity<OfferProps, number> {
  get id(): NumericId {
    return NumericId.create(this._id.getId());
  }

  get category(): OfferCategory {
    return this.props.category;
  }

  get status(): OfferStatus {
    return this.props.status;
  }

  get name(): MultilingualString {
    return this.props.name;
  }

  get header(): MultilingualString {
    return this.props.header;
  }

  get img1(): MultilingualString {
    return this.props.img1;
  }

  get img2(): MultilingualString {
    return this.props.img2;
  }

  get description(): MultilingualString {
    return this.props.description;
  }

  get details(): MultilingualString {
    return this.props.details;
  }
}
