import { HttpData } from "../../../config/Config";
import NetworkController from "../../network/NetworkController";
import IOfferDataSource, { OfferDTO, OfferEditData, FileDTO, FilePathDTO } from "./IOfferDataSource";

export class OfferDataSourceError extends Error {
  constructor(message: string) {
    super(`[OfferDataSource] Error - ${message}`);
  }
}

export default class OfferDataSource implements IOfferDataSource {
  constructor(private nwc: NetworkController, private httpConfig: HttpData) {}

  async getOffer(id: number): Promise<OfferDTO> {
    try {
      const offer: any = await this.nwc.request({
        url: `/utility-app/offers/${id}`,
        method: "GET",
        useToken: true
      });
      return offer.data;
    } catch (error: any) {
      throw new OfferDataSourceError(`[getOffer] - ${error.message}`);
    }
  }

  async getOffers(): Promise<OfferDTO[]> {
    try {
      const offers: any = await this.nwc.request({
        url: "/utility-app/offers",
        method: "GET",
        useToken: true
      });
      return offers.data;
    } catch (error: any) {
      throw new OfferDataSourceError(`[getOffers] - ${error.message}`);
    }
  }

  async activateOffer(id: number): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/offers/${id}/activate`,
        method: "POST",
        useToken: true
      });
    } catch (error: any) {
      throw new OfferDataSourceError(`[activateOffer] - ${error.message}`);
    }
  }

  async closeOffer(id: number): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/offers/${id}/close`,
        method: "POST",
        useToken: true
      });
    } catch (error: any) {
      throw new OfferDataSourceError(`[closeOffer] - ${error.message}`);
    }
  }

  async getOfferImages(selectedImg: string): Promise<string[]> {
    try {
      const offers: any = await this.nwc.request({
        url: `/utility-app/images/${selectedImg}`,
        method: "GET",
        useToken: true
      });

      return offers.data.images;
    } catch (error: any) {
      throw new OfferDataSourceError(`[getOfferImages] - ${error.message}`);
    }
  }

  async createOffer(offer: OfferEditData): Promise<void> {
    try {
      const data = JSON.stringify(offer);

      await this.nwc.request({
        url: `/utility-app/offers/`,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        useToken: true,
        data
      });
    } catch (error: any) {
      throw new OfferDataSourceError(`[createOffer] - ${error.message}`);
    }
  }

  async updateOffer(id: number, offer: OfferEditData): Promise<void> {
    try {
      const data = JSON.stringify(offer);
      await this.nwc.request({
        url: `/utility-app/offers/${id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        useToken: true,
        data
      });
    } catch (error: any) {
      throw new OfferDataSourceError(`[updateOffer] - ${error.message}`);
    }
  }

  async uploadOfferImageFile(offerImageFilesDTO: FileDTO[], utility: string): Promise<FilePathDTO[]> {
    try {
      const result: FilePathDTO[] = await Promise.all(
        offerImageFilesDTO.map(async (offerImageFileDTO) => {
          const offerImageFileName = offerImageFileDTO.file.name;
          const offerImageFileType = offerImageFileDTO.type;
          const data = new FormData();
          data.append("img", offerImageFileDTO.file);

          await this.nwc.request({
            url: `/utility-app/upload-image/${offerImageFileType}`,
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data"
            },
            useToken: true,
            data
          });

          const offerImageType = offerImageFileType.replace("-image", "s");
          const path = `${this.httpConfig.baseUrl}/static/images/${utility}/${offerImageType}/${offerImageFileName}`;

          return { name: offerImageFileName, path };
        })
      );

      return result;
    } catch (error: any) {
      throw new OfferDataSourceError(`[uploadOfferImageFile] - ${error.message}`);
    }
  }
}
