import { ValueObject } from "../../../base/valueObject/ValueObject";
import Offer from "../entity/Offer";

export class OfferListError extends Error {
    constructor(message: string) {
        super(`[OfferList] Error - ${message}`)
    }
}

interface OfferListProps {
    offers: Offer[]
}

export default class OfferList extends ValueObject<OfferListProps> {
    get offers(): Offer[] {
        return this.props.offers
    }

    private constructor(props: OfferListProps) {
        super(props)
    }

    public static create(offers: Offer[]): OfferList {
        if (!offers)
            throw new OfferListError('Offer list must not be undefined')        
        return new OfferList({ offers })
    }

    public equals(vo: ValueObject<OfferListProps>): boolean {
        if (this.props.offers.length !== vo.props.offers.length)
            return false

        let result = this.props.offers.filter(o1 => vo.props.offers.some(o2 => o1.id.isEqual(o2.id)));
       
        return result.length === this.props.offers.length
    }
}