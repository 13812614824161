import StringId from "../../../domain/base/valueObject/uniqueEntityID/StringId";
import Customer from "../../../domain/modules/customer/entity/Customer";
import CustomerList from "../../../domain/modules/customer/valueObject/CustomerList";
import ICustomerDataSource, { EnergyInfoDTO } from "../../dataSource/customerDataSource/ICustomerDataSource";
import ICustomerMapperFactory from "./factory/ICustomerMapperFactory";
import ICustomerRepository from "./ICustomerRepository";

export class CustomerRepositoryError extends Error {
  constructor(message: string) {
    super(`[CustomerRepository] Error - ${message}`);
  }
}

export default class CustomerRepository implements ICustomerRepository {
  constructor(
    private _dataSource: ICustomerDataSource,
    private _customerMapperFactory: ICustomerMapperFactory
  ) {}

  async getCustomer(id: StringId): Promise<Customer> {
    try {
      const customer = await this._dataSource.getCustomer(id.getId());
      return this._customerMapperFactory.getCustomerMapper().map(customer);
    } catch (error: any) {
      throw new CustomerRepositoryError(`[getCustomer] - ${error.message}`);
    }
  }

  async getCustomers(): Promise<CustomerList> {
    try {
      const customers = await this._dataSource.getCustomers();
      const customersMap = customers.map((customerData) =>
        this._customerMapperFactory.getCustomerMapper().map(customerData)
      );
      return CustomerList.create(customersMap);
    } catch (error: any) {
      throw new CustomerRepositoryError(`[getCustomers] - ${error.message}`);
    }
  }

  async getCustomerEnergyInfo(customerId: StringId): Promise<EnergyInfoDTO> {
    try {
      return await this._dataSource.getCustomerEnergyInfo(customerId.getId());
    } catch (err: any) {
      throw new CustomerRepositoryError(`[getCustomerEnergyInfo] - ${err.message}`);
    }
  }

  async setMeterId(mainMeter: string, obisCode: string): Promise<void> {
    try {
      return await this._dataSource.setMeterId(mainMeter, obisCode);
    } catch (err: any) {
      throw new CustomerRepositoryError(`[setMeterId] - ${err.message}`);
    }
  }
}
