import { ValueObject } from "../../../base/valueObject/ValueObject";

interface OfferCategoryProps {
    value: string
}

export default class OfferCategory extends ValueObject<OfferCategoryProps> {
    private static DEFAULT_OFFER_CATEGORY = "ENERGY_PRODUCT";

    get value(): string {
        return this.props.value;
    }

    private constructor(props: OfferCategoryProps) {
        super(props);
    }

    public static create(value: string): OfferCategory {
        return new OfferCategory({ value: OfferCategory.validate(value) ? value : OfferCategory.DEFAULT_OFFER_CATEGORY });
    }

    private static validate(value?: string): boolean {
        if (value === undefined || value === null)
            return false;
        else return true;
    }

    public equals(vo: ValueObject<OfferCategoryProps>): boolean {
        return vo.props.value === this.props.value;
    }
}