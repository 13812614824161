import StringId from "../../../../domain/base/valueObject/uniqueEntityID/StringId";
import { OrderHistoryDTO, OrderListItemDTO } from "../../../dataSource/orderDataSource/IOrderDataSource";
import IMapper from "../../../IMapper";
import NotEmptyString from "../../../../domain/base/valueObject/NotEmptyString";
import OrderStatus from "../../../../domain/modules/order/valueObject/OrderStatus";
import NumericId from "../../../../domain/base/valueObject/uniqueEntityID/NumericId";
import OfferInfo from "../../../../domain/modules/order/valueObject/OfferInfo";
import OrderHistoryList from "../../../../domain/modules/order/valueObject/OrderHistoryList";
import OrderHistory from "../../../../domain/modules/order/valueObject/OrderHistory";
import Timestamp from "../../../../domain/modules/Timestamp";
import OrderListItem from "../../../../domain/modules/order/entity/OrderListItem";
import { OrderMapperError } from "./OrderMapper";
import MultilingualString from "../../../../domain/modules/MultilingualString";
import NoteList from "../../../../domain/modules/order/valueObject/NoteList";

export class OrderListItemMapperError extends Error {
  constructor(message: string) {
    super(`[OrderMapper] Error - ${message}`);
  }
}

export default class OrderListItemMapper implements IMapper<OrderListItemDTO, OrderListItem> {
  map(input: OrderListItemDTO): OrderListItem {
    try {
      const { id, declineReason, orderNumber, agreement, customer, name, offer, history } = input;

      return new OrderListItem(
        {
          customerId: customer ? StringId.create(customer) : undefined,
          orderNumber: NotEmptyString.create(orderNumber),
          notes: NoteList.create([]),
          status: OrderStatus.create(history[history.length - 1].status),
          declineReason: declineReason ? NotEmptyString.create(declineReason) : undefined,
          offerInfo: OfferInfo.create(
            NumericId.create(offer),
            MultilingualString.create(name.en, name.de, name.fr, name.it)
          ),
          orderHistory: OrderHistoryList.create(this.convertHistoryDTOList(history)),
          agreementId: agreement ? NumericId.create(agreement) : undefined
        },
        NumericId.create(id)
      );
    } catch (err: any) {
      throw new OrderMapperError(err.message);
    }
  }

  private convertHistoryDTOList(list: OrderHistoryDTO[]): OrderHistory[] {
    return list.map((item) => OrderHistory.create(OrderStatus.create(item.status), Timestamp.create(item.timestamp)));
  }
}
