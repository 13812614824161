import NetworkController from "../../network/NetworkController";
import IOrderDataSource, { CreateOrderDTO, OrderDTO, OrderListItemDTO } from "./IOrderDataSource";

export class OrderDataSourceError extends Error {
  constructor(message: string) {
    super(`[OrderDataSource] Error - ${message}`);
  }
}

export default class OrderDataSource implements IOrderDataSource {
  constructor(private nwc: NetworkController) {}

  async getOrders(): Promise<OrderListItemDTO[]> {
    try {
      const orders: any = await this.nwc.request({
        url: "/utility-app/orders",
        method: "GET",
        useToken: true,
      });
      return orders.data;
    } catch (error: any) {
      throw new OrderDataSourceError(`[getOrders] - ${error.message}`);
    }
  }

  async getOrdersForCustomer(customerId: string): Promise<OrderListItemDTO[]> {
    try {
      const orders: any = await this.nwc.request({
        url: `/utility-app/orders?customer=${customerId}`,
        method: "GET",
        useToken: true,
      });
      return orders.data;
    } catch (error: any) {
      throw new OrderDataSourceError(`[getOrdersForCustomer] - ${error.message}`);
    }
  }

  async getOrder(orderNumber: string): Promise<OrderDTO> {
    try {
      const order: any = await this.nwc.request({
        url: `/utility-app/orders/${orderNumber}?identifier=orderNumber`,
        method: "GET",
        useToken: true,
      });
      return order.data;
    } catch (error: any) {
      throw new OrderDataSourceError(`[getOrder] - ${error.message}`);
    }
  }

  async changeStatus(id: string, status: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/orders/${id}/change-status`,
        method: "PUT",
        useToken: true,
        data: { status },
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[changeStatus] - ${error.message}`);
    }
  }

  async completeOrder(id: string, startDate: string, expirationDate: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/orders/${id}/complete`,
        method: "PUT",
        useToken: true,
        data: { startDate, expirationDate },
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[completeOrder] - ${error.message}`);
    }
  }

  async declineOrder(id: string, declineReason: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/orders/${id}/decline`,
        method: "PUT",
        useToken: true,
        data: { declineReason },
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[declineOrder] - ${error.message}`);
    }
  }

  async updateOrderNote(id: string, note: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/orders/${id}/note`,
        method: "POST",
        useToken: true,
        data: { note },
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[updateOrderNote] - ${error.message}`);
    }
  }

  async deleteOrder(id: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/orders/${id}`,
        method: "DELETE",
        useToken: true,
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[deleteOrder] - ${error.message}`);
    }
  }

  async deactivateOrder(orderId: string, deactivationReason: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/orders/${orderId}/deactivate`,
        method: "PUT",
        useToken: true,
        data: { deactivationReason },
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[deactivateOrder] - ${error.message}`);
    }
  }

  async selectConfigurations(orderId: string, configurations: string[]): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/orders/${orderId}/fw-suggestion`,
        method: "POST",
        useToken: true,
        data: { suggestion: configurations },
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[selectConfigurations] - ${error.message}`);
    }
  }

  async createOrder(order: CreateOrderDTO): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/orders",
        method: "POST",
        useToken: true,
        data: { ...order },
      });
    } catch (error: any) {
      throw new OrderDataSourceError(`[createOrder] - ${error.message}`);
    }
  }
}
