import NotEmptyString from "../../../domain/base/valueObject/NotEmptyString";
import Token from "../../../domain/modules/auth/valueObject/Token";
import Email from "../../../domain/modules/Email";
import MyProfile from "../../../domain/modules/myProfile/entity/MyProfile";
import ITokenDataSource from "../../dataSource/tokenDataSource/ITokenDataSource";
import IMyProfileMapperFactory from "../myProfileRepository/factory/IMyProfileMapperFactory";
import ITokenRepositoryMapperFactory from "./factory/ITokenRepositoryMapperFactory";
import { ITokenRepository } from "./ITokenRepository";

export class TokenRepositoryError extends Error {
    constructor(message: string) {
        super(`[TokenRepository] Error - ${message}`);
    }
}

export class TokenRepository implements ITokenRepository {

    constructor(
        private _dataSource: ITokenDataSource,
        private _mapperFactory: ITokenRepositoryMapperFactory,
        private _profileMapperFactory: IMyProfileMapperFactory
    ) { }

    async getToken(email: NotEmptyString, password: NotEmptyString): Promise<Token> {
        try {
            return this._mapperFactory.getTokenMapper().map(
                await this._dataSource.getToken(email.value, password.value)
            );
        } catch (err: any) {
            throw new TokenRepositoryError(`[getToken] - ${err.message}`);
        }
    }

    storeToken(token: Token) {
        return this._dataSource.storeToken(this._mapperFactory.getTokenDataMapper().map(token));
    }

    hasToken(): boolean {
        return this._dataSource.hasToken();
    }

    clearToken(): void {
        return this._dataSource.clearToken();
    }

    decodeToken(token: Token): MyProfile {
        const profile = this._dataSource.decodeToken(this._mapperFactory.getTokenDataMapper().map(token));
        return this._profileMapperFactory.getMyProfileMapper().map(profile);
    }

    async checkAuth(): Promise<void> {
        try {
            await this._dataSource.checkToken();
        }
        catch (err: any) {
            throw new TokenRepositoryError(`[checkAuth] - ${err.message}`);
        }
    }

    getStoredToken(): Token {
        return this._mapperFactory.getTokenMapper().map(
            this._dataSource.getStoredToken()
        )
    }

    async signOut(token: Token): Promise<void> {
        try {
            await this._dataSource.signOut(this._mapperFactory.getTokenDataMapper().map(token));
        } catch (err: any) {
            throw new TokenRepositoryError(`[signOut] - ${err.message}`)
        }
    }

    async forgotPassword(email: Email): Promise<void> {
        try {
            await this._dataSource.forgotPassword(email.value);
        } catch (err: any) {
            throw new TokenRepositoryError(`[forgotPassword] - ${err.message}`);
        }
    }
}