import CustomerList from "../../../../domain/modules/customer/valueObject/CustomerList";
import IGetOrdersGateway from "../../../../domain/modules/order/gateway/IGetOrdersGateway";
import OrderList from "../../../../domain/modules/order/valueObject/OrderList";
import ICustomerRepository from "../../customerRepository/ICustomerRepository";
import IOrderRepository from "../IOrderRepository";

export class GetOrdersGatewayError extends Error {
  constructor(message: string) {
    super(`[GetOrdersGateway] Error - ${message}`);
  }
}

export default class GetOrdersGateway implements IGetOrdersGateway {
  constructor(private _orderRepository: IOrderRepository, private _customerRepository: ICustomerRepository) {}

  public async getOrders(): Promise<OrderList> {
    try {
      return await this._orderRepository.getOrders();
    } catch (err: any) {
      throw new GetOrdersGatewayError(err.message);
    }
  }

  public async getCustomers(): Promise<CustomerList> {
    try {
      return await this._customerRepository.getCustomers();
    } catch (err: any) {
      throw new GetOrdersGatewayError(err.message);
    }
  }
}
