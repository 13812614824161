import NetworkController from "../../network/NetworkController";
import { MessageViewModel, MessageCreateDTO } from "../../repository/messageRepository/IMessageRepository";
import IMessageDataSource from "./IMessageDataSource";

export default class MessageDataSource implements IMessageDataSource {
  constructor(private nwc: NetworkController) {}

  public async getMessages(): Promise<MessageViewModel[]> {
    try {
      const messages: any = await this.nwc.request({
        url: "/utility-app/messages",
        method: "GET",
        useToken: true
      });
      return messages.data;
    } catch (error: any) {
      throw new Error(`[getMessages] - ${error.message}`);
    }
  }

  public async getMessage(messageId: string): Promise<MessageViewModel> {
    try {
      const message: any = await this.nwc.request({
        url: `/utility-app/messages/${messageId}`,
        method: "GET",
        useToken: true
      });
      return message.data;
    } catch (error: any) {
      throw new Error(`[getMessage] - ${error.message}`);
    }
  }

  public async createMessage(message: MessageCreateDTO): Promise<void> {
    try {
      await this.nwc.request({
        url: "/utility-app/messages",
        method: "POST",
        useToken: true,
        data: message
      });
    } catch (error: any) {
      throw new Error(`[createMessage] - ${error.message}`);
    }
  }
}
