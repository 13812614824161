export type MultilingualStringViewModel = {
    en: string;
    de: string;
    fr: string;
    it: string;
}

const getDefaultMultilingualStringViewModel = () => {
    return {
        en: "",
        de: "",
        fr: "",
        it: ""
    }
}

export default getDefaultMultilingualStringViewModel;