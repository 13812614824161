import { ValueObject } from "../../../base/valueObject/ValueObject";
import Address from "../../Address";
import Email from "../../Email";
import Name from "../../Name";
import MeterInfo from "./MeterInfo";

export class OrderDetailsError extends Error {
  constructor(message: string) {
    super(`[OrderDetails] Error - ${message}`);
  }
}

interface OrderDetailsProps {
  name: Name;
  email: Email;
  address: Address;
  telephone: string;
  meterInfo: MeterInfo;
}

export default class OrderDetails extends ValueObject<OrderDetailsProps> {
  get name(): Name {
    return this.props.name;
  }

  get email(): Email {
    return this.props.email;
  }

  get address(): Address {
    return this.props.address;
  }

  get meterInfo(): MeterInfo {
    return this.props.meterInfo;
  }

  get telephone(): string {
    return this.props.telephone;
  }

  private constructor(props: OrderDetailsProps) {
    super(props);
  }

  public static create(
    name: Name,
    email: Email,
    address: Address,
    meterInfo: MeterInfo,
    telephone: string
  ): OrderDetails {
    return new OrderDetails({ name, email, address, meterInfo, telephone });
  }

  public equals(vo: ValueObject<OrderDetailsProps>): boolean {
    if (
      vo.props.name.equals(this.props.name) &&
      vo.props.email.equals(this.props.email) &&
      vo.props.address.equals(this.props.address) &&
      vo.props.meterInfo.equals(this.props.meterInfo)
    )
      return true;
    else return false;
  }
}
