import Order from "../../../../domain/modules/order/entity/Order";
import OrderHistory from "../../../../domain/modules/order/valueObject/OrderHistory";
import { OrderDTO, OrderHistoryDTO } from "../../../dataSource/orderDataSource/IOrderDataSource";
import IMapper from "../../../IMapper";

export default class OrderDataMapper implements IMapper<Order, OrderDTO> {
  map(input: Order): OrderDTO {
    const {
      id,
      customerId,
      orderNumber,
      details,
      offerInfo,
      notes,
      orderHistory,
      declineReason,
      deactivationReason,
      agreementId
    } = input;

    return {
      id: id.getId(),
      orderNumber: orderNumber.value,
      customer: customerId ? customerId.getId() : null,
      info: {
        firstName: details.name.firstName.value,
        lastName: details.name.lastName.value,
        email: details.email.value,
        address: details.address.street.value,
        zip: details.address.zipCode.value,
        city: details.address.city.value,
        meterManufacturer: details.meterInfo.manufacturer,
        meterModel: details.meterInfo.model,
        meterHasPowerOutletAccess: details.meterInfo.features.powerOutletAccess,
        meterHasWiFiAccess: details.meterInfo.features.wifiAccess,
        hasWiFiAtHome: details.meterInfo.features.wifiAtHome,
        telephone: details.telephone
      },
      offer: offerInfo.id.getId(),
      name: {
        en: offerInfo.title.en,
        de: offerInfo.title.de,
        fr: offerInfo.title.fr,
        it: offerInfo.title.it
      },
      note: notes.notes.map((n) => {
        return {
          autoGenerated: n.autoGenerated,
          authorizedBy: n.authorizedBy.value,
          body: n.body,
          timestamp: n.timestamp.toISOString()
        };
      }),
      history: this.mapHistoryList(orderHistory.list),
      declineReason: declineReason ? declineReason.value : undefined,
      deactivationReason: deactivationReason ? deactivationReason.value : undefined,
      agreement: agreementId ? agreementId.getId() : undefined
    };
  }

  private mapHistoryList(list: OrderHistory[]): OrderHistoryDTO[] {
    return list.map((item) => this.mapHistoryObject(item));
  }

  private mapHistoryObject(history: OrderHistory): OrderHistoryDTO {
    return {
      status: history.status.value,
      timestamp: history.timestamp.value
    };
  }
}
