import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ForgotPasswordState {
  email: string;
  success: boolean;
  error?: string;
}

const initialState: ForgotPasswordState = {
  email: "",
  success: false
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    inputChanged: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.email = payload.value;
    },
    passwordUpdated: (state) => {
      state.success = true;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
    },
    viewUnloaded: () => initialState
  }
});

export const { inputChanged, errorHappened, viewUnloaded, passwordUpdated } = forgotPasswordSlice.actions;
