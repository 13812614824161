import { ValueObject } from "../../../base/valueObject/ValueObject";
import Note from "./Note";

export class NoteListError extends Error {
  constructor(message: string) {
    super(`[NoteList] Error - ${message}`);
  }
}

interface NoteListProps {
  notes: Note[];
}

export default class NoteList extends ValueObject<NoteListProps> {
  get notes(): Note[] {
    return this.props.notes;
  }

  private constructor(props: NoteListProps) {
    super(props);
  }

  public static create(notes: Note[]): NoteList {
    if (!notes) throw new NoteListError("Note list must not be undefined");
    return new NoteList({ notes });
  }

  public equals(vo: ValueObject<NoteListProps>): boolean {
    if (this.props.notes.length !== vo.props.notes.length) return false;

    let result = this.props.notes.filter((o1) =>
      vo.props.notes.some(
        (o2) =>
          o1.autoGenerated === o2.autoGenerated &&
          o1.authorizedBy.equals(o2.authorizedBy) &&
          o1.body === o2.body &&
          o1.timestamp.toISOString() === o2.timestamp.toISOString()
      )
    );

    return result.length === this.props.notes.length;
  }
}
