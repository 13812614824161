import NotEmptyString from "../../../base/valueObject/NotEmptyString";
import { ValueObject } from "../../../base/valueObject/ValueObject";

export class OfferImageError extends Error {
  constructor(message: string) {
    super(`[OfferImage] Error - ${message}`);
  }
}

interface OfferImageProps {
  url: NotEmptyString;
}

export default class OfferImage extends ValueObject<OfferImageProps> {
  get url(): NotEmptyString {
    return this.props.url;
  }

  private constructor(props: OfferImageProps) {
    super(props);
  }

  public static create(url: NotEmptyString): OfferImage {
    return new OfferImage({ url });
  }

  public equals(vo: ValueObject<OfferImageProps>): boolean {
    return vo.props.url.value === this.url.value;
  }
}
