import { ValueObject } from "../../../base/valueObject/ValueObject";
import OrderListItem from "../entity/OrderListItem";

export class OrderListError extends Error {
    constructor(message: string) {
        super(`[OrderList] Error - ${message}`)
    }
}

interface OrderListProps {
    orders: OrderListItem[]
}

export default class OrderList extends ValueObject<OrderListProps> {
    get orders(): OrderListItem[] {
        return this.props.orders
    }

    private constructor(props: OrderListProps) {
        super(props)
    }

    public static create(orders: OrderListItem[]): OrderList {
        if (!orders)
            throw new OrderListError('Order list must not be undefined')
        return new OrderList({ orders })
    }

    public equals(vo: ValueObject<OrderListProps>): boolean {

        if (this.props.orders.length !== vo.props.orders.length)
            return false

        let result = this.props.orders.filter(o1 => vo.props.orders.some(o2 => o1.id.isEqual(o2.id)));

        return result.length === this.props.orders.length
    }
}