import { CreateOrderViewModel } from "../../../../gui/presenter/order/viewModel/OrderViewModel";
import { CreateOrderDTO } from "../../../../infrastructure/dataSource/orderDataSource/IOrderDataSource";

export default class CreateOrderInputProcessor {
  public static map(data: CreateOrderViewModel): CreateOrderDTO {
    return {
      userId: data.customerId,
      offerId: parseInt(data.offerId),
      technicalInfo: {
        answers: {
          meter_look_type: {
            answerId: this.mapMeterLookType(data.meterLookType),
            additional: {},
          },
          meter_type_model: {
            answerId: "zika-patika",
            additional: {
              q8Input: data.meterTypeModel,
            },
          },
          wifi_access: {
            answerId: this.mapWifiAccess(data.wifiAccess),
            additional: {},
          },
          power_outlet_near_electric_meter: {
            answerId: this.mapPowerOutletNearMeter(data.powerOutletNearElectricMeter),
            additional: {},
          },
        },
      },
      personalInfo: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        address: data.address,
        zipCode: data.zip,
        city: data.city,
        telephone: data.telephone || "",
      },
    };
  }

  private static mapMeterLookType(meterLookType: string) {
    switch (meterLookType) {
      case "mechanical":
        return "q12A1";

      case "digital":
        return "q12A2";

      case "unknown":
      default:
        return "q12A3";
    }
  }

  private static mapWifiAccess(wifiAccess: string) {
    switch (wifiAccess) {
      case "yes":
        return "q10A1";

      case "noAccess":
        return "q10A2";

      case "noWifi":
      default:
        return "q10A3";
    }
  }

  private static mapPowerOutletNearMeter(outletNearMeter: string) {
    switch (outletNearMeter) {
      case "yes":
        return "q11A1";

      case "no":
      default:
        return "q11A2";
    }
  }
}
