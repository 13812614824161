import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DistrictViewModel,
  NotificationSubscriberViewModel,
  SettingsViewModel
} from "../../../infrastructure/repository/settingsRepository/ISettingsRepository";

interface SettingsState {
  zipCodes: { zip: string }[];
  districts: DistrictViewModel[];
  notificationSubscribers: NotificationSubscriberViewModel[];
  zipCodeToAdd: string;
  subscriberToAdd: string;
  districtToAdd: string;
  pageSizeZipCodes: number;
  pageNumberZipCodes: number;
  pageSizeSubscribers: number;
  pageNumberSubscribers: number;
  pageSizeDistricts: number;
  pageNumberDistricts: number;
  loading: boolean;
  error?: string;
}

const initialState: SettingsState = {
  zipCodes: [],
  districts: [],
  notificationSubscribers: [],
  zipCodeToAdd: "",
  subscriberToAdd: "",
  districtToAdd: "",
  pageSizeZipCodes: 5,
  pageNumberZipCodes: 0,
  pageSizeSubscribers: 5,
  pageNumberSubscribers: 0,
  pageSizeDistricts: 5,
  pageNumberDistricts: 0,
  loading: false
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    settingsLoaded: (state, { payload }: PayloadAction<{ settings: SettingsViewModel }>) => {
      const { settings } = payload;
      const newState = Object.entries(settings).reduce((acc, curr) => {
        let key = curr[0];
        let val = curr[1];
        return { ...acc, [key]: val };
      }, {});

      return {
        ...state,
        loading: false,
        ...newState
      };
    },
    districtsLoaded: (state, { payload }: PayloadAction<{ districts: DistrictViewModel[] }>) => {
      state.loading = false;
      state.districts = payload.districts;
    },
    districtDeleted: (state, { payload }: PayloadAction<{ district: string }>) => {
      const filteredDistricts = state.districts.filter((d) => d.name !== payload.district);
      state.districts = filteredDistricts;
      state.loading = false;
    },
    districtsPageNumberSet: (state, { payload }: PayloadAction<number>) => {
      state.pageNumberDistricts = payload;
    },
    districtsPageSizeSet: (state, { payload }: PayloadAction<number>) => {
      state.pageSizeDistricts = payload;
    },
    districtUpdated: (state, { payload }: PayloadAction<{ oldName: string; newName: string }>) => {
      const updatedDistricts = state.districts.map((d) => {
        if (d.name === payload.oldName) {
          return { ...d, name: payload.newName };
        } else return d;
      });

      state.districts = updatedDistricts;
      state.loading = false;
    },
    districtInputChanged: (state, { payload }: PayloadAction<string>) => {
      state.districtToAdd = payload;
    },
    districtAdded: (state) => {
      const newDistricts = [...state.districts, { name: state.districtToAdd, createdAt: new Date().toString() }];
      state.loading = false;
      state.districts = newDistricts;
      state.districtToAdd = "";
    },
    zipCodeDeleted: (state, { payload }: PayloadAction<{ zipCodes: { zip: string }[] }>) => {
      state.loading = false;
      state.zipCodes = payload.zipCodes;
    },
    zipCodeInputChanged: (state, { payload }: PayloadAction<string>) => {
      state.zipCodeToAdd = payload;
    },
    zipCodeAdded: (state, { payload }: PayloadAction<{ zipCodes: { zip: string }[] }>) => {
      state.loading = false;
      state.zipCodes = payload.zipCodes;
      state.zipCodeToAdd = "";
    },
    zipCodesPageNumberSet: (state, { payload }: PayloadAction<number>) => {
      state.pageNumberZipCodes = payload;
    },
    zipCodesPageSizeSet: (state, { payload }: PayloadAction<number>) => {
      state.pageSizeZipCodes = payload;
    },
    subscriberDeleted: (state, { payload }: PayloadAction<{ email: string }>) => {
      const filtered = state.notificationSubscribers.filter((sub) => sub.destination !== payload.email);
      state.notificationSubscribers = filtered;
      state.loading = false;
    },
    subscriberInputChanged: (state, { payload }: PayloadAction<string>) => {
      state.subscriberToAdd = payload;
    },
    subscriberAdded: (state, { payload }: PayloadAction<{ subscribers: NotificationSubscriberViewModel[] }>) => {
      state.loading = false;
      state.notificationSubscribers = payload.subscribers;
      state.subscriberToAdd = "";
    },
    subscribersPageNumberSet: (state, { payload }: PayloadAction<number>) => {
      state.pageNumberSubscribers = payload;
    },
    subscribersPageSizeSet: (state, { payload }: PayloadAction<number>) => {
      state.pageSizeSubscribers = payload;
    },
    errorHappened: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    requestStarted: (state) => {
      state.loading = true;
    },
    requestFinished: (state) => {
      state.loading = false;
    },
    viewUnloaded: () => initialState
  }
});

export const {
  errorHappened,
  requestStarted,
  requestFinished,
  viewUnloaded,
  districtsLoaded,
  settingsLoaded,
  districtDeleted,
  zipCodeInputChanged,
  zipCodeAdded,
  zipCodeDeleted,
  zipCodesPageNumberSet,
  zipCodesPageSizeSet,
  districtsPageNumberSet,
  districtsPageSizeSet,
  districtUpdated,
  districtAdded,
  districtInputChanged,
  subscriberAdded,
  subscriberInputChanged,
  subscriberDeleted,
  subscribersPageNumberSet,
  subscribersPageSizeSet
} = settingsSlice.actions;
