import NetworkController from "../../network/NetworkController";
import IEventDataSource, { EventStatisticsDTO, EventTemplateDTO, EventTemplateEditData, EventDTO, EventMagnitudeDTO } from "./IEventDataSource";

export class EventDataSourceError extends Error {
  constructor(message: string) {
    super(`[EventDataSource] Error - ${message}`);
  }
}

export default class EventDataSource implements IEventDataSource {
  constructor(private nwc: NetworkController) {}

  async getEvent(id: string): Promise<EventDTO> {
    try {
      const events: any = await this.nwc.request({
        url: `/utility-app/notify/events/${id}`,
        method: "GET",
        useToken: true,
      });
      return events.data;
    } catch (error: any) {
      throw new EventDataSourceError(`[getEvent] - ${error.message}`);
    }
  }

  async getEventTemplates(): Promise<EventTemplateDTO[]> {
    try {
      const events: any = await this.nwc.request({
        url: `/utility-app/notify/templates`,
        method: "GET",
        useToken: true,
      });
      return events.data;
    } catch (error: any) {
      throw new EventDataSourceError(`[getEventTemplates] - ${error.message}`);
    }
  }

  async getHistoryEvents(from: string, to: string): Promise<EventDTO[]> {
    try {
      const events: any = await this.nwc.request({
        url: `/utility-app/notify/events/history?from=${from}&to=${to}`,
        method: "GET",
        useToken: true,
      });
      return events.data;
    } catch (error: any) {
      throw new EventDataSourceError(`[getHistoryEvents] - ${error.message}`);
    }
  }

  async getEventTemplate(id: string): Promise<EventTemplateDTO> {
    try {
      const event: any = await this.nwc.request({
        url: `/utility-app/notify/templates/${id}`,
        method: "GET",
        useToken: true,
      });
      return event.data;
    } catch (error: any) {
      throw new EventDataSourceError(`[getEventTemplate] - ${error.message}`);
    }
  }

  async createEventTemplate(eventTemplate: EventTemplateEditData): Promise<void> {
    try {
      const data = JSON.stringify(eventTemplate);

      await this.nwc.request({
        url: `/utility-app/notify/templates`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        useToken: true,
        data,
      });
    } catch (error: any) {
      throw new EventDataSourceError(`[createEventTemplate] - ${error.message}`);
    }
  }

  async updateEventTemplate(id: string, eventTemplate: EventTemplateEditData): Promise<void> {
    try {
      const data = JSON.stringify(eventTemplate);
      await this.nwc.request({
        url: `/utility-app/notify/templates/${id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        useToken: true,
        data,
      });
    } catch (error: any) {
      throw new EventDataSourceError(`[updateEventTemplate] - ${error.message}`);
    }
  }

  async deleteEventTemplate(id: string): Promise<void> {
    try {
      await this.nwc.request({
        url: `/utility-app/notify/templates/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        useToken: true,
      });
    } catch (error: any) {
      throw new Error(`[deleteEventTemplate] Error`, { cause: error });
    }
  }

  async getEventStatistics(id: string): Promise<EventStatisticsDTO> {
    try {
      const event: any = await this.nwc.request({
        url: `/utility-app/notify/events/${id}/statistics`,
        method: "GET",
        useToken: true,
      });

      return event.data;
    } catch (error: any) {
      throw new EventDataSourceError(`[getEventStatistics] - ${error.message}`);
    }
  }

  async getEventMagnitude(districts: string[], type: string): Promise<EventMagnitudeDTO> {
    try {
      let query = `?type=${type}`;
      districts.forEach((district) => {
        query += `&district=${district}`;
      });

      const event: any = await this.nwc.request({
        url: `/utility-app/notify/events/event-magnitude${query}`,
        method: "GET",
        useToken: true,
      });
      return event.data;
    } catch (error: any) {
      throw new EventDataSourceError(`[getEventMagnitude] - ${error.message}`);
    }
  }

  async getLiveEventsForTemplate(templateId: string): Promise<EventDTO[]> {
    try {
      const events: any = await this.nwc.request({
        url: `/utility-app/notify/events/live?template=${templateId}`,
        method: "GET",
        useToken: true,
      });
      return events.data;
    } catch (error: any) {
      throw new EventDataSourceError(`[getLiveEventsForTemplate] - ${error.message}`);
    }
  }
}
