import { MultilingualStringViewModel } from "../../../../../gui/presenter/offer/viewModel/MultilingualStringViewModel";
import { OfferImageFilePathViewModel } from "../../../../../gui/presenter/offer/viewModel/OfferImageFilePathViewModel";
import MultilingualString from "../../../MultilingualString";
import OfferImageFilePath from "../../valueObject/OfferImageFilePath";

export class OfferImageFilePathFactoryError extends Error {
    constructor(message: string) {
        super(`[UploadOfferImagesFactory] Error - ${message}`);
    }
}

export default class OfferImageFilePathFactory {

    public static create(inputData: OfferImageFilePathViewModel): OfferImageFilePath {
        const { img1, img2 } = inputData;

        return OfferImageFilePath.create(this.createMultilingualData(img1), this.createMultilingualData(img2));
    }

    private static createMultilingualData(input: MultilingualStringViewModel) {
        return MultilingualString.create(
            input.en,
            input.de,
            input.fr,
            input.it
        )
    }

}